import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const VoucherPerMonthCard: FunctionComponent<GraphAnalyticsCardProps<{ sold: number[]; redeemed: number[] }>> = ({
    data,
    fetchData,
    labels,
}) => {
    // Voucher Per Month - Bar Chart
    const dataVoucherPerMonth: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Verkauft',
                data: data.sold,
                backgroundColor: ['#e6765a'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'white',
                },
            },
            {
                label: 'Eingelöst',
                data: data.redeemed,
                backgroundColor: ['#558ba0'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'white',
                },
            },
        ],
    };

    const optionsVoucherPerMonth: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                align: 'center',
            },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
        },
        scales: {
            y: {
                display: false,
                stacked: true,
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Gutscheine pro Monat"
            subtitle="Gesamt pro Monat"
            tooltip=""
            editableConfigIdentifier="voucher_per_month"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataVoucherPerMonth}
                options={optionsVoucherPerMonth}
            />
        </AnalyticsCard>
    );
};

export default VoucherPerMonthCard;
