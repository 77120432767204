import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const AverageAgeCard: FunctionComponent<GraphAnalyticsCardProps<ICustomerAge>> = ({ data }) => {
    return (
        <AnalyticsCard
            live
            title="Durchschnittsalter"
            subtitle="Jahre"
            tooltip="Alter aller Personen / Alle Buchungen"
        >
            <div className="grid grid-cols-1a1 h-full">
                <div className="text-right">
                    <p className="mt-3 text-5.5xl font-bold">{data.averageFemale.toString()}</p>
                    <p className="text-2xl mt-12">Weiblich</p>
                </div>
                <p className="my-auto mx-14 font-bold text-7xl">&Oslash;</p>
                <div className="text-left">
                    <p className="mt-3 text-5.5xl font-bold">{data.averageMale.toString()}</p>
                    <p className="text-2xl mt-12">Männlich</p>
                </div>
            </div>
        </AnalyticsCard>
    );
};

export default AverageAgeCard;
