import { Timeslot, TotalOrderVolumeResponse } from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '..';
import { loadAnalyticsData } from '../storedAnalytics';

const loadSocialMediaValues = async (
    token: string,
    identifier: string,
): Promise<{
    labels: string[];
    data: { currentCw: number[]; currentMinusOne: number[]; currentMinusTwo: number[] };
}> => {
    const response = await loadAnalyticsData(token, identifier, Timeslot.fourWeeks);
    // we only need 3 weeks

    const threeWeekResponse = response.result.slice(1, 4);

    const kws = threeWeekResponse.map((singleLineGraphData) => singleLineGraphData.interval);

    const labels = threeWeekResponse
        .find((singleLineGraphData) => singleLineGraphData.values.length)
        ?.values?.map((value) => value.identifier);

    if (!labels?.length || threeWeekResponse.length < 3) {
        return {
            labels: [],
            data: {
                currentCw: [],
                currentMinusOne: [],
                currentMinusTwo: [],
            },
        };
    }

    const data = kws.map((kw) => {
        const singleLineGraphData = threeWeekResponse.find((foundData) => foundData.interval === kw);
        const values = labels.map((label) => {
            const value = singleLineGraphData?.values.find((searchValue) => searchValue.identifier === label)?.value;
            return value || 0;
        });
        return values;
    });

    return {
        labels,
        data: {
            currentCw: data[2],
            currentMinusOne: data[1],
            currentMinusTwo: data[0],
        },
    };
};

export const fetchKPIMarketing = async (token: string): Promise<ISalesVolumeData> => {
    const totalOrderVolumeRequest = sendGetRequest<TotalOrderVolumeResponse>(
        token,
        `/statistics/revenue/totalOrderVolume?timeslot=twelveWeeks`,
    );
    const socialAttentionRequest = loadSocialMediaValues(token, 'social_attention');

    const engagementRateRequest = loadSocialMediaValues(token, 'engagement_rate');

    const [totalOrderVolumeRes, socialAttention, engagementRate] = await Promise.all([
        totalOrderVolumeRequest,
        socialAttentionRequest,
        engagementRateRequest,
    ]);

    const { result: voucherPerMonth } = await loadAnalyticsData(token, 'voucher_per_month', Timeslot.lastTwelveMonths);

    const { result: voucherPerYear } = await loadAnalyticsData(token, 'voucher_per_year', Timeslot.fourYears);

    return {
        totalOrderVolume: totalOrderVolumeRes.result.map((member) => ({
            label: `KW ${member.calendarWeek}`,
            value: Math.floor(member.score),
        })),
        socialAttention,
        engagementRate,
        voucherPerMonth: {
            labels: voucherPerMonth.map((item) => item.interval),
            data: {
                sold: voucherPerMonth.map((item) => item.values.find((value) => value.identifier === 'sold')?.value || 0),
                redeemed: voucherPerMonth.map((item) => item.values.find((value) => value.identifier === 'redeemed')?.value || 0),
            },
        },
        voucherPerYear: {
            labels: voucherPerYear.map((item) => item.interval),
            data: {
                sold: voucherPerYear.map((item) => item.values.find((value) => value.identifier === 'sold')?.value || 0),
                redeemed: voucherPerYear.map((item) => item.values.find((value) => value.identifier === 'redeemed')?.value || 0),
            },
        },
        wasFetched: true,
    };
};
