import { FunctionComponent, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useSalesVolumeData } from '../context/KPIMarketing/SalesVolumeProvider';
import LoadSpinner from '../components/LoadSpinner';
import OrderVolumeCard from '../components/analytics/cards/OrderVolume';
import EngagementRateCard from '../components/analytics/cards/EngagementRate';
import SocialAttentionCard from '../components/analytics/cards/SocialAttention';
import VoucherPerYearCard from '../components/analytics/cards/VoucherPerYear';
import VoucherPerMonthCard from '../components/analytics/cards/VoucherPerMonth';

ChartJS.register(LineElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin);

const KPIMarketingAdSalesVolume: FunctionComponent = () => {
    const { data, fetchData } = useSalesVolumeData();

    useEffect(() => {
        fetchData();
    }, []);

    return data.wasFetched ? (
        <>
            <OrderVolumeCard
                labels={data.totalOrderVolume.map((item) => item.label)}
                data={data.totalOrderVolume.map((item) => Math.round(item.value / 1000))}
            />
            <SocialAttentionCard
                data={data.socialAttention.data}
                labels={data.socialAttention.labels}
                fetchData={fetchData}
            />
            <EngagementRateCard
                data={data.engagementRate.data}
                labels={data.engagementRate.labels}
                fetchData={fetchData}
            />

            <VoucherPerMonthCard
                data={data.voucherPerMonth.data}
                labels={data.voucherPerMonth.labels}
                fetchData={fetchData}
            />

            <VoucherPerYearCard
                data={data.voucherPerYear.data}
                labels={data.voucherPerYear.labels}
                fetchData={fetchData}
            />
        </>
    ) : (
        <LoadSpinner />
    );
};

export default KPIMarketingAdSalesVolume;
