import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const AllTagRatings: FunctionComponent<
    GraphAnalyticsCardProps<{ outletDisplayname: string; ratings: { date: number; rating: number | null }[] }[]> & { tag: string }
> = ({ data, tag }) => {
    const allTagsData: ChartData<'line', { date: number; rating: number | null }[]> = {
        datasets: data.map((outlet, index) => ({
            label: outlet.outletDisplayname,
            data: outlet.ratings,
            fill: false,
            borderColor: ['#a1c1ce', '#e6765a', '#edcd77', '#558ba0', '#90AF76'][index],
            pointBackgroundColor: ['#a1c1ce', '#e6765a', '#edcd77', '#558ba0', '#90AF76'][index],
            tension: 0.4,
        })),
    };

    const allTagsOptions: ChartOptions<'line'> = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
            },
        },
        plugins: {
            datalabels: {
                display: false,
            },
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
        },
        parsing: {
            xAxisKey: 'date',
            yAxisKey: 'rating',
        },

        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title={tag}
            tooltip={`Bewertungen von allen Filialen über die letzten 12 Monate von der Kategorie ${tag}`}
        >
            <Line
                data={allTagsData}
                options={allTagsOptions}
            />
        </AnalyticsCard>
    );
};

export default AllTagRatings;
