import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const FluctuationCard: FunctionComponent<GraphAnalyticsCardProps> = ({ labels, data, fetchData }) => {
    return (
        <AnalyticsCard
            title="Aufgelaufene Fluktuation"
            subtitle="in Filialen | in %"
            tooltip="Austritte kumuliert / dursch. MA-Anzahl"
            editableConfigIdentifier="fluctuation"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            label: 'Filialen',
                            data,
                            borderColor: '#558ba0',
                            backgroundColor: '#558ba0',
                            datalabels: {
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                    ],
                }}
                options={{
                    plugins: {
                        tooltip: { enabled: false },
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: -4,
                        },
                    },
                    layout: {
                        padding: {
                            top: 20,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default FluctuationCard;
