import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const ResignationsCard: FunctionComponent<GraphAnalyticsCardProps<IFluctuationPage['resignations']['data']>> = (props) => {
    const { labels, data, fetchData } = props;

    return (
        <AnalyticsCard
            title="Austritte gesamt"
            subtitle="Anzahl"
            tooltip="Austritte nach Jobbezeichnung (Daten beziehen sich auf die Gesamtsumme der Eintritte v.o.)"
            editableConfigIdentifier="resignations"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            label: 'Service',
                            data: data.service,
                            backgroundColor: '#a1c1ce',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                        {
                            label: 'Reinigung',
                            data: data.cleaning,
                            backgroundColor: '#558ba0',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                        {
                            label: 'Management',
                            data: data.management,
                            backgroundColor: '#90AF76',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                    ],
                }}
                options={{
                    plugins: {
                        tooltip: { enabled: false },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    layout: {
                        padding: {
                            top: 5,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default ResignationsCard;
