import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchKpiBookingRate } from '../../api/kpiMarketing/bookingRate';

const initialData: IBookingRateData = {
    bookingRate: [],
    futureQuotient: [[], [], [], []],
    utilizationByOutlet: [],
    utilizationTotal: [],
    newFriends: [],
    subscribersThatBecameFriends: 0,
    friendsBookingComparison: [],
    friendsRevenueComparison: [],
    wasFetched: false,
};

const BookingRateDataContext = ProviderBuilder.buildContext<IBookingRateData>();

const BookingRateDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiMarketingDataValue = ProviderBuilder.buildProvider<IBookingRateData>(initialData, fetchKpiBookingRate);

    return <BookingRateDataContext.Provider value={kpiMarketingDataValue}>{children}</BookingRateDataContext.Provider>;
};

const useBookingRateData = ProviderBuilder.buildUseHook(BookingRateDataContext, 'useBookingRateData');

export default BookingRateDataProvider;
export { useBookingRateData, BookingRateDataContext };
