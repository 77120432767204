import { FunctionComponent, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useKpiMroData } from '../context/KPIMro/KPIMroProvider';
import DefectQuota1Card from '../components/analytics/cards/DefectQuota1';
import DefectQuota2Card from '../components/analytics/cards/DefectQuota2';
import Effectiveness1Card from '../components/analytics/cards/Effectiveness1';
import Effectiveness2Card from '../components/analytics/cards/Effectiveness2';
import ReactionTimeCard from '../components/analytics/cards/ReactionTime';
import DefectQuotaByStatusCard from '../components/analytics/cards/DefectQuotaByStatus';
import DefectQuotaByOrganizationCard from '../components/analytics/cards/DefectQuotaByOrganization';

ChartJS.register(LineElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin);

const KPIMarketingAdAuslastung: FunctionComponent = () => {
    const { data, fetchData } = useKpiMroData();
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <DefectQuota1Card
                data={data.defectQuota1.data}
                labels={data.defectQuota1.labels}
            />

            <DefectQuota2Card
                data={data.defectQuota2.data}
                labels={data.defectQuota2.labels}
            />

            <DefectQuotaByStatusCard
                data={{
                    pending: data.defectQuotaByStatus.map((item) => item.pending),
                    open: data.defectQuotaByStatus.map((item) => item.open),
                    closed: data.defectQuotaByStatus.map((item) => item.closed),
                    makeover: data.defectQuotaByStatus.map((item) => item.makeover),
                    draft: data.defectQuotaByStatus.map((item) => item.draft),
                    renovation: data.defectQuotaByStatus.map((item) => item.renovation),
                    resolved: data.defectQuotaByStatus.map((item) => item.resolved),
                }}
                labels={data.defectQuotaByStatus.map((item) => item.label)}
            />

            <DefectQuotaByOrganizationCard
                data={Array.from(
                    data.defectQuotaByOrganization
                        .reduce((acc, curr) => {
                            curr.data.forEach((item) => {
                                if (acc.has(item.organizationName)) {
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    acc.get(item.organizationName)!.push(item.defectCount);
                                } else {
                                    acc.set(item.organizationName, [item.defectCount]);
                                }
                            });
                            return acc;
                        }, new Map<string, number[]>())
                        .entries(),
                ).map(([key, value]) => ({
                    organizationName: key,
                    defectCount: value,
                }))}
                labels={data.defectQuotaByOrganization.map((item) => item.label)}
            />

            <Effectiveness1Card
                data={data.effectiveness1.data}
                fetchData={fetchData}
                labels={data.effectiveness1.labels}
            />

            <Effectiveness2Card
                data={data.effectiveness2.data}
                fetchData={fetchData}
                labels={data.effectiveness2.labels}
            />

            <ReactionTimeCard
                data={{ pending: data.reactionTime.pending, open: data.reactionTime.open, closed: data.reactionTime.closed }}
                labels={data.reactionTime.labels}
            />
        </>
    );
};

export default KPIMarketingAdAuslastung;
