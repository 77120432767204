import { FunctionComponent } from 'react';
import { Chart } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const SicknessRateCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        outlet: number[];
        administration: number[];
        dsges: number[];
    }>
> = (props) => {
    const { labels, data, fetchData } = props;
    return (
        <AnalyticsCard
            title="Krankenquote DS Filialen"
            subtitle="in %"
            tooltip="AU Tage / Arbeitstage * Anzahl MA"
            editableConfigIdentifier="sickness_quota"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Chart
                type="bar"
                data={{
                    labels,
                    datasets: [
                        {
                            label: 'Service',
                            data: data.administration,
                            backgroundColor: '#a1c1ce',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                            order: 2,
                        },
                        {
                            label: 'Reinigung',
                            data: data.outlet,
                            backgroundColor: '#558ba0',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                            order: 3,
                        },
                        {
                            label: 'DSges',
                            data: data.dsges,
                            borderColor: '#e6765a',
                            pointBackgroundColor: '#e6765a',
                            pointRadius: 12,
                            pointHoverRadius: 14,
                            tension: 0.4,
                            datalabels: {
                                display: true,
                                font: {
                                    size: 18,
                                    weight: 'bold',
                                },
                                color: 'white',
                            },
                            order: 1,
                            type: 'line',
                        },
                    ],
                }}
                options={{
                    plugins: {
                        tooltip: { enabled: false },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                    },
                    layout: {
                        padding: {
                            top: 5,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default SicknessRateCard;
