import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const NewFriendsCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        fromNewBookers: number[];
        fromReturningBookers: number[];
    }>
> = ({ data, labels }) => (
    <AnalyticsCard
        title="Zahl neuer Friends"
        subtitle="Bestandskunden vs. Neukunden"
        tooltip="Darstellung pro Woche für die letzten 12 Wochen"
        live
    >
        <Bar
            data={{
                labels,
                datasets: [
                    {
                        data: data.fromNewBookers,
                        label: 'Neubucher',
                        backgroundColor: '#a1c1ce',
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: -4,
                            font: {
                                weight: 'bold',
                                size: 18,
                            },
                            color: 'black',
                        },
                    },
                    {
                        data: data.fromReturningBookers,
                        label: 'Bestandskunden',
                        backgroundColor: '#558ba0',
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: -4,
                            font: {
                                weight: 'bold',
                                size: 18,
                            },
                            color: 'black',
                        },
                    },
                ],
            }}
            options={{
                plugins: {
                    tooltip: { enabled: false },
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            usePointStyle: true,
                        },
                    },
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        offset: -4,
                    },
                },
                layout: {
                    padding: {
                        top: 5,
                    },
                },
                scales: {
                    y: {
                        display: false,
                    },
                },
                maintainAspectRatio: false,
            }}
        />
    </AnalyticsCard>
);

export default NewFriendsCard;
