import { FunctionComponent, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useBookingRateData } from '../context/KPIMarketing/BookingRateProvider';
import LoadSpinner from '../components/LoadSpinner';
import UtilizationTotalCard from '../components/analytics/cards/UtilizationTotal';
import WorkloadOutletCard from '../components/analytics/cards/WorkloadOutlet';
import BookingQouteCard from '../components/analytics/cards/BookingQoute';
import FutureQuotientCard from '../components/analytics/cards/FutureQuotient';
import NewFriendsCard from '../components/analytics/cards/NewFriends';
import SubscribersToFriendsCard from '../components/analytics/cards/SubscribersToFriends';
import FriendsBookingComparison from '../components/analytics/cards/FriendsBookingComparison';
import FriendsRevenueComparison from '../components/analytics/cards/FriendsRevenueComparison';

ChartJS.register(LineElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin);

const KPIMarketingAdBookingRate: FunctionComponent = () => {
    const { data, fetchData } = useBookingRateData();

    useEffect(() => {
        fetchData();
    }, []);

    return data.wasFetched ? (
        <>
            <UtilizationTotalCard
                data={data.utilizationTotal.map((item) => item.value)}
                labels={data.utilizationTotal.map((item) => item.label)}
            />

            <WorkloadOutletCard
                data={data.utilizationByOutlet.map((item) => item.value)}
                labels={data.utilizationByOutlet.map((item) => item.label)}
            />

            <BookingQouteCard
                data={data.bookingRate.map((item) => item.value)}
                labels={data.bookingRate.map((item) => item.label)}
            />

            <FutureQuotientCard
                data={{
                    futureQuotient: data.futureQuotient,
                }}
                weekOffset={1}
                tooltip="Berechnung der Auslastung für die nächste Kalenderwoche in der Zukunft pro Filiale"
            />
            <FutureQuotientCard
                data={{
                    futureQuotient: data.futureQuotient,
                }}
                weekOffset={2}
                tooltip="Berechnung der Auslastung für die übernächste Kalenderwoche in der Zukunft pro Filiale"
            />
            <FutureQuotientCard
                data={{
                    futureQuotient: data.futureQuotient,
                }}
                weekOffset={3}
                tooltip="Berechnung der Auslastung für die Kalenderwoche drei Wochen in der Zukunft pro Filiale"
            />

            <FutureQuotientCard
                data={{
                    futureQuotient: data.futureQuotient,
                }}
                weekOffset={4}
                tooltip="Berechnung der Auslastung für die Kalenderwoche vier Wochen in der Zukunft pro Filiale"
            />

            <NewFriendsCard
                data={{
                    fromNewBookers: data.newFriends.map((item) => item.fromNewBookers),
                    fromReturningBookers: data.newFriends.map((item) => item.fromReturningBookers),
                }}
                labels={data.newFriends.map((item) => `KW ${item.calendarWeek}`)}
            />

            <SubscribersToFriendsCard data={data.subscribersThatBecameFriends} />

            <FriendsBookingComparison
                data={{
                    friendsBookingCount: data.friendsBookingComparison.map((item) => item.friendsBookingCount),
                    nonFriendsBookingCount: data.friendsBookingComparison.map((item) => item.nonFriendsBookingCount),
                }}
                labels={data.friendsBookingComparison.map((item) => `${item.month}`)}
            />

            <FriendsRevenueComparison
                data={{
                    friendsRevenue: data.friendsRevenueComparison.map((item) => item.friendsRevenue),
                    nonFriendsRevenue: data.friendsRevenueComparison.map((item) => item.nonFriendsRevenue),
                }}
                labels={data.friendsRevenueComparison.map((item) => `${item.month}`)}
            />
        </>
    ) : (
        <LoadSpinner />
    );
};

export default KPIMarketingAdBookingRate;
