import { ChartData, ChartOptions, Point } from 'chart.js/dist/types/index';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const TimeDeliveringCard: FunctionComponent<GraphAnalyticsCardProps> = () => {
    // Bestellung/Lieferung - Line Chart
    const dataTimeDelivering: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels: ['DTM1', 'DTM2', 'BOM1', 'ESS1', 'CGN1', 'DUS1', 'BER1', 'DUI1', 'WUP1'],
        datasets: [
            {
                label: 'Durchschnittliche Dauer bis zur Lieferung',
                data: [10, 8, 7, 10, 8, 8, 10, 9, 9],
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsTimeDelivering: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Bestellung/Lieferung"
            subtitle="&Oslash; Dauer | in Minuten"
        >
            <Bar
                data={dataTimeDelivering}
                options={optionsTimeDelivering}
            />
        </AnalyticsCard>
    );
};

export default TimeDeliveringCard;
