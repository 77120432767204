import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchFrequencyData } from '../../api/kpiMarketing/frequency';

const initialData: IFrequencyData = {
    websiteVisitors: [],
    conversionsInPercent: [],
    sources: [],
    conversionByChannel: {
        labels: [],
        data: [],
    },
    roasByChannel: {
        labels: [],
        data: [],
    },
    marketingCostByChannel: {
        labels: [],
        data: [],
    },
    revenueTotal: [],
    paddingQuota: [],
    salesShares: [],
    newCustomerBookings: [],
    customerAcquisitionCosts: {
        labels: [],
        data: [],
    },
    averageOrderValue: {
        data: [],
        labels: [],
    },
    bon: {
        bonTotal: 0,
        consumption: 0,
        flex: 0,
        membercard: 0,
        packages: 0,
        textiles: 0,
        upgrades: 0,
        extension: 0,
    },
    forecastBookingQuota: {
        labels: [],
        currentQuotient: [],
        gainQuotient: [],
    },
    wasFetched: false,
};

const FrequencyContext = ProviderBuilder.buildContext<IFrequencyData>();

const FrequencyProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const frequencyDataValue = ProviderBuilder.buildProvider<IFrequencyData>(initialData, fetchFrequencyData);

    return <FrequencyContext.Provider value={frequencyDataValue}>{children}</FrequencyContext.Provider>;
};

const useFrequencyData = ProviderBuilder.buildUseHook(FrequencyContext, 'useFrequencyData');

export default FrequencyProvider;
export { useFrequencyData, FrequencyContext };
