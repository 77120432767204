import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const HourlyOutputOutletCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, fetchData, labels }) => {
    // Stundenleistung pro Filiale - Bar chart

    const valuesCountHourlyOutputOutlets = data.length;
    const dataHourlyOutputOutletsAverage = valuesCountHourlyOutputOutlets
        ? data.reduce((a, b) => a + b) / valuesCountHourlyOutputOutlets
        : 0;

    const dataHourlyOutputOutlets: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Previous',
                data,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsHourlyOutputOutlets: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: dataHourlyOutputOutletsAverage,
                        yMax: dataHourlyOutputOutletsAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: dataHourlyOutputOutletsAverage + 5,
                        content: [`Durchschnitt: ${dataHourlyOutputOutletsAverage.toFixed(1)}`],
                        color: 'gray',
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Stundenleistung"
            subtitle="pro Filiale | in Std."
            editableConfigIdentifier="hourly_output_per_outlet"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataHourlyOutputOutlets}
                options={optionsHourlyOutputOutlets}
            />
        </AnalyticsCard>
    );
};
export default HourlyOutputOutletCard;
