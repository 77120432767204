import { FunctionComponent } from 'react';

import { ChartData, ChartOptions, Point } from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { arrayAverage } from '../../../util';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const NPSOutletsCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        npsByOutlet: {
            outletId: number;
            displayName: string;
            value: number;
        }[];
    }>
> = ({ data }) => {
    // NPS je Filiale - Bar chart
    const dataNPSOutletsValues = data.npsByOutlet.map((item) => item.value);
    const dataNPSOutletsAverage = arrayAverage(dataNPSOutletsValues);

    const dataNPSOutlets: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels: data.npsByOutlet.map((item) => item.displayName),
        datasets: [
            {
                label: 'Previous',
                data: dataNPSOutletsValues,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsNPSOutlets: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: dataNPSOutletsAverage,
                        yMax: dataNPSOutletsAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: dataNPSOutletsAverage + 1,
                        content: [`Durchschnitt: ${dataNPSOutletsAverage.toFixed(0)}`],
                        color: 'gray',
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="NPS Filialen"
            subtitle="letzte KW"
            live
        >
            <Bar
                data={dataNPSOutlets}
                options={optionsNPSOutlets}
            />
        </AnalyticsCard>
    );
};

export default NPSOutletsCard;
