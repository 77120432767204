import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const CustomerAquisitionCostsCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, fetchData, labels }) => {
    // Customer Aquistion Costs - Line Chart
    const dataCAC: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                borderColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const cacDataAverage = data.reduce((a, b) => a + b, 0) / data.length;
    const optionsCAC: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: cacDataAverage,
                        yMax: cacDataAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: cacDataAverage + 2,
                        content: [`Durchschnitt: ${cacDataAverage.toFixed(2)}`],
                        color: 'gray',
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 25,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Customer Aquisition Costs"
            subtitle="in Tsd. €"
            editableConfigIdentifier="customer_aquisition_costs"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Line
                data={dataCAC}
                options={optionsCAC}
            />
        </AnalyticsCard>
    );
};

export default CustomerAquisitionCostsCard;
