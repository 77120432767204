import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const HourlyOutputTotalCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, fetchData, labels }) => {
    // Stundenleistung Gesamt - Line Chart
    const dataHourlyOutputTotal: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                borderColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsHourlyOutputTotal: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Stundenleistung"
            subtitle="je Personalstunde gesamt | in Tsd. €"
            editableConfigIdentifier="hourly_output"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Line
                data={dataHourlyOutputTotal}
                options={optionsHourlyOutputTotal}
            />
        </AnalyticsCard>
    );
};

export default HourlyOutputTotalCard;
