import { FunctionComponent, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useFluctuationPage } from '../context/KPIPeopleAndCulture/FluctuationProvider';
import LoadSpinner from '../components/LoadSpinner';
import FluctuationCard from '../components/analytics/cards/Fluctuation';
import SicknessRateCard from '../components/analytics/cards/SicknessRate';
import RecruitmentCostCard from '../components/analytics/cards/RecruitmentCost';
import OpenPositionsCard from '../components/analytics/cards/OpenPositions';
import OpenPositionsDuration from '../components/analytics/cards/OpenPositionsDuration';
import AdmissionsCard from '../components/analytics/cards/Admissions';
import ResignationsCard from '../components/analytics/cards/Resignations';
import SaldoTotalCard from '../components/analytics/cards/SaldoTotal';
import PersonalCostsToRevenueCard from '../components/analytics/cards/PersonalCostsToRevenue';
import HourlyOutputTotalCard from '../components/analytics/cards/HourlyOutputTotal';

ChartJS.register(LineElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin);

const KPIMarketingAdFluctuation: FunctionComponent = () => {
    const { data, fetchData } = useFluctuationPage();

    useEffect(() => {
        fetchData();
    }, []);

    if (!data.wasFetched) return <LoadSpinner />;
    return (
        <>
            <FluctuationCard
                fetchData={fetchData}
                data={data.fluctuation.data}
                labels={data.fluctuation.labels}
            />
            <SicknessRateCard
                fetchData={fetchData}
                data={data.sicknessQuota.data}
                labels={data.sicknessQuota.labels}
            />
            <AdmissionsCard
                fetchData={fetchData}
                data={data.admissions.data}
                labels={data.admissions.labels}
            />
            <ResignationsCard
                fetchData={fetchData}
                data={data.resignations.data}
                labels={data.resignations.labels}
            />
            <OpenPositionsCard
                data={{
                    service: data.openPositions.service.data,
                    cleaning: data.openPositions.cleaning.data,
                }}
                labels={data.openPositions.cleaning.labels}
                fetchData={fetchData}
            />
            <RecruitmentCostCard
                data={data.recruitingCost.data}
                labels={data.recruitingCost.labels}
                fetchData={fetchData}
            />
            <OpenPositionsDuration
                data={data.openPositionDuration.data}
                labels={data.openPositionDuration.labels}
                fetchData={fetchData}
            />
            <SaldoTotalCard
                data={data.saldoTotal.data}
                labels={data.saldoTotal.labels}
                fetchData={fetchData}
            />
            <PersonalCostsToRevenueCard
                data={data.personalCostsToRevenue.data}
                labels={data.personalCostsToRevenue.labels}
                fetchData={fetchData}
            />

            <HourlyOutputTotalCard
                data={data.hourlyOutput.data}
                fetchData={fetchData}
                labels={data.hourlyOutput.labels}
            />
        </>
    );
};

export default KPIMarketingAdFluctuation;
