/* eslint-disable max-len */
import { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRoutes } from '../../context/RouteProvider';
import menuIconOpen from '../../icons/icon__distribution.svg';
import menuIconClose from '../../icons/icon__close.svg';

interface IAnalyticsProps {
    children: React.ReactNode;
    title: string;
    subtitle?: string;
}

const Analytics: FunctionComponent<IAnalyticsProps> = ({ children, title, subtitle }) => {
    const { routes, setCurrentRoute } = useRoutes();
    const [navOpenState, setNavOpenState] = useState(false);
    const [navMenuIconState, setNavMenuIconState] = useState(false);

    const toggleNav = (): void => {
        setNavOpenState(!navOpenState);
        setNavMenuIconState(!navMenuIconState);
    };

    return (
        <main>
            <div className="sticky flex flex-col sm:flex-row justify-between top-0 z-10 px-4 md:px-10 xl:px-20">
                <h4 className="inline-block px-6 py-1.5 mt-4 sm:mb-9 text-2xl rounded-full shadow-sm tracking-widest bg-boho">
                    <span className="uppercase">{title}</span>
                    {subtitle && <span>{` | ${subtitle}`}</span>}
                </h4>

                <button
                    type="button"
                    className={`inline-block px-6 py-1.5 mt-4 mb-9 text-2xl rounded-full shadow-sm tracking-widest bg-boho ${
                        navOpenState ? 'z-20' : ''
                    }`}
                    onClick={(): void => {
                        toggleNav();
                    }}
                >
                    <img
                        src={navMenuIconState ? menuIconClose : menuIconOpen}
                        alt="menu icon"
                        className="h-6 mx-auto"
                    />
                </button>
                <div
                    className={`${
                        navOpenState ? 'visible' : 'hidden'
                    } fixed top-0 right-0 bottom-0 left-0 bg-black/50 z-10 backdrop-blur-sm`}
                />
                <div
                    className={`absolute w-[calc(100%-2rem)] md:w-[calc(100%-5rem)] xl:w-[calc(100%-10rem)] flex flex-col mt-40 sm:mt-24 z-20 ${
                        navOpenState ? 'h-[min-content] p-4' : 'h-0'
                    } overflow-hidden bg-white rounded shadow-sm`}
                >
                    {routes.map((route) => (
                        <NavLink
                            className="p-2 text-2xl hover:bg-beige border-b last:border-b-0 duration-200"
                            id={route.path}
                            to={route.path}
                            key={`route_${route.path}}`}
                            onClick={(): void => {
                                setCurrentRoute(route);
                                toggleNav();
                            }}
                            end
                        >
                            {route.title} {route.subtitle}
                        </NavLink>
                    ))}
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-[repeat(auto-fill,_minmax(37rem,_1fr))] gap-5 px-4 md:px-10 xl:px-20">
                {children}
            </div>
        </main>
    );
};

export default Analytics;
