import { ChartData, Point, ChartOptions } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const NPSTotalCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        npsTotal: {
            label: string;
            value: number;
        }[];
    }>
> = ({ data }) => {
    // NPS Gesamt - Line Chart
    const dataNPSTotalData: ChartData<'line', (number | Point | null)[], unknown> = {
        labels: data.npsTotal.map((item) => item.label),
        datasets: [
            {
                label: 'Offene Stellen',
                data: data.npsTotal.map((item) => item.value),
                borderColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsNPSTotal: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="NPS Gesamt"
            live
            tooltip="NPS = Promotoren (in % aller Befragten) - Detraktoren (in % aller Befragten) pro Kalenderwoche"
        >
            <Line
                data={dataNPSTotalData}
                options={optionsNPSTotal}
            />
        </AnalyticsCard>
    );
};

export default NPSTotalCard;
