import { useEffect, FunctionComponent } from 'react';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useCustomerData } from '../context/CustomerDataProvider';
import LoadSpinner from '../components/LoadSpinner';
import GenderCard from '../components/analytics/cards/Gender';
import AverageAgeCard from '../components/analytics/cards/AverageAge';
import AverageOfStayCard from '../components/analytics/cards/AverageOfStay';
import AveragePersonsCard from '../components/analytics/cards/AveragePersons';
import GoogleRatingCard from '../components/analytics/cards/GoogleRating';
import NPSTrendCard from '../components/analytics/cards/NPSTrend';
import NPSTotalCard from '../components/analytics/cards/NPSTotal';
import NPSOutletsCard from '../components/analytics/cards/NPSOutlet';
import CustomerInTimeCard from '../components/analytics/cards/CustomerInTime';
import TimeDeliveringCard from '../components/analytics/cards/TimeDeliveringCard';
import NPSBookingCard from '../components/analytics/cards/NPSBooking';
import ProducitvityCRMCard from '../components/analytics/cards/ProductivityCRM';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin, BarElement);

const CustomerData: FunctionComponent = () => {
    const { data, fetchData } = useCustomerData();

    useEffect(() => {
        fetchData();
    }, []);

    if (!data.wasFetched) return <LoadSpinner />;

    return (
        <>
            <GenderCard data={data.gender} />

            <AverageAgeCard data={data.age} />

            <AverageOfStayCard data={data.averageStay} />

            <AveragePersonsCard data={data.averagePersons} />

            <GoogleRatingCard
                data={data.googleRating}
                fetchData={fetchData}
            />

            <NPSTrendCard
                data={{
                    trend: data.trend,
                }}
            />

            <NPSTotalCard data={{ npsTotal: data.npsTotal }} />

            <NPSOutletsCard data={{ npsByOutlet: data.npsByOutlet }} />

            <CustomerInTimeCard data={[]} />

            <TimeDeliveringCard data={[]} />

            <NPSBookingCard
                data={data.npsBookings.data}
                labels={data.npsBookings.labels}
            />

            <ProducitvityCRMCard
                data={data.crmProductivity.data}
                labels={data.crmProductivity.labels}
                fetchData={fetchData}
            />
        </>
    );
};

export default CustomerData;
