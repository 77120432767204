import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Pie } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

// TODO: There's gotta be a cleaner way to do this
const SalesBonCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        consumption: number;
        textiles: number;
        packages: number;
        upgrades: number;
        membercard: number;
        flex: number;
        extension: number;
    }>
> = ({ data }) => {
    // Umsatz Bon - Pie Chart
    const dataSalesBon: ChartData<'pie', (number | Point | null)[], unknown> = {
        labels: ['Verzehr', 'Textil', 'Pakete', 'Upgrades', 'Membercards', 'Flex', 'Verlängerung'],
        datasets: [
            {
                label: 'Bon gesamt',
                data: [data.consumption, data.textiles, data.packages, data.upgrades, data.membercard, data.flex, data.extension],
                backgroundColor: ['#a1c1ce', '#e6765a', '#edcd77', '#558ba0', '#90AF76', '#dee9ee'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsSalesBon: ChartOptions<'pie'> = {
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: 14,
                    },
                },
            },
            datalabels: {
                anchor: 'center',
                align: 'end',
                offset: 35,
            },
        },
        layout: {
            padding: {
                top: 30,
                bottom: 30,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Umsatz Bon"
            subtitle="der letzten vier Wochen | in Tsd. €"
            tooltip="Aufteilung des kumulierten Umsatzes der letzten vier Kalenderwochen"
            live
        >
            <Pie
                data={dataSalesBon}
                options={optionsSalesBon}
            />
        </AnalyticsCard>
    );
};
export default SalesBonCard;
