import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const DefectQuotaByOrganizationCard: FunctionComponent<
    GraphAnalyticsCardProps<
        {
            organizationName: string;
            defectCount: number[];
        }[]
    >
> = ({ data, labels }) => {
    // Defektquote bei Status - Line Chart
    const color = ['#a1c1ce', '#e6765a', '#edcd77', '#558ba0', '#90AF76', '#dee9ee'];
    const dataDefectQuoteByOrganization: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: data.map((item) => ({
            label: item.organizationName,
            data: item.defectCount,
            borderColor: color[data.indexOf(item)],
            backgroundColor: color[data.indexOf(item)],
            pointRadius: 8,
            pointHoverRadius: 10,
            tension: 0.4,
            datalabels: {
                font: {
                    weight: 'bold',
                    size: 18,
                },
                color: 'black',
            },
        })),
    };

    const optionsDefectQuoteByOrganization: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        layout: {
            padding: {
                top: 0,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Defektquote Organization"
            subtitle="Defekte | Anzahl"
            tooltip="Defekte je Organization pro KW"
            live
        >
            <Line
                data={dataDefectQuoteByOrganization}
                options={optionsDefectQuoteByOrganization}
            />
        </AnalyticsCard>
    );
};

export default DefectQuotaByOrganizationCard;
