import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const SaldoTotalCard: FunctionComponent<GraphAnalyticsCardProps<IFluctuationPage['saldoTotal']['data']>> = (props) => {
    const { labels, data, fetchData } = props;

    return (
        <AnalyticsCard
            title="Gesamtsaldo"
            subtitle=""
            tooltip="Anzahl der Minus- und/oder Überstunden Gesamt pro KST pro Monat"
            editableConfigIdentifier="saldo_total"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Line
                data={{
                    labels,
                    datasets: [
                        {
                            label: 'Saldo',
                            data: data.saldo,
                            borderColor: '#558ba0',
                            pointBackgroundColor: '#558ba0',
                            pointRadius: 8,
                            pointHoverRadius: 10,
                            tension: 0.4,
                            datalabels: {
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                        {
                            label: 'Auszahlung',
                            data: data.payout,
                            borderColor: '#558ba0',
                            pointBackgroundColor: '#558ba0',
                            pointRadius: 8,
                            pointHoverRadius: 10,
                            tension: 0.4,
                            datalabels: {
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                    ],
                }}
                options={{
                    plugins: {
                        tooltip: { enabled: false },
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: -4,
                        },
                        legend: {
                            display: false,
                        },
                    },
                    layout: {
                        padding: {
                            top: 35,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default SaldoTotalCard;
