import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const SalesSharesCard: FunctionComponent<
    GraphAnalyticsCardProps<
        {
            calendarWeek: string;
            shares: {
                wellnessHours: string;
                packages: string;
                birthdayUpgrade: string;
                candlelightUpgrade: string;
                flex: string;
                extensions: string;
            };
        }[]
    >
> = ({ data }) => {
    return (
        <AnalyticsCard
            title="Getätigter Umsatz"
            tooltip="Aufteilung des Umsatzes der letzten vier Kalenderwochen"
            live
        >
            <table className="w-full">
                <thead>
                    <tr>
                        <th aria-label="empty field">&nbsp;</th>
                        {data.map((item) => (
                            <th
                                key={item.calendarWeek}
                                className="text-right pr-1 whitespace-nowrap"
                            >
                                {item.calendarWeek}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-xl leading-6 bg-beige/50">
                        <td className="pl-1">Stunden</td>
                        {data.map((item) => (
                            <td
                                key={`wellnesshours_${item.calendarWeek}`}
                                className="text-right whitespace-nowrap"
                            >
                                {item.shares.wellnessHours} €
                            </td>
                        ))}
                    </tr>
                    <tr className="text-xl leading-6">
                        <td className="pl-1">Pakete</td>
                        {data.map((item) => (
                            <td
                                key={`packages_${item.calendarWeek}`}
                                className="text-right whitespace-nowrap"
                            >
                                {item.shares.packages} €
                            </td>
                        ))}
                    </tr>
                    <tr className="text-xl leading-6 bg-beige/50">
                        <td className="pl-1">Birthday</td>
                        {data.map((item) => (
                            <td
                                key={`birthday_${item.calendarWeek}`}
                                className="text-right whitespace-nowrap"
                            >
                                {item.shares.birthdayUpgrade} €
                            </td>
                        ))}
                    </tr>
                    <tr className="text-xl leading-6">
                        <td className="pl-1">Candlelight</td>
                        {data.map((item) => (
                            <td
                                key={`candlelight_${item.calendarWeek}`}
                                className="text-right whitespace-nowrap"
                            >
                                {item.shares.candlelightUpgrade} €
                            </td>
                        ))}
                    </tr>
                    <tr className="text-xl leading-6 bg-beige/50">
                        <td className="pl-1">Flex</td>
                        {data.map((item) => (
                            <td
                                key={`flex_${item.calendarWeek}`}
                                className="text-right whitespace-nowrap"
                            >
                                {item.shares.flex} €
                            </td>
                        ))}
                    </tr>
                    <tr className="text-xl leading-6">
                        <td className="pl-1">Verlängerungen</td>
                        {data.map((item) => (
                            <td
                                key={`extension_${item.calendarWeek}`}
                                className="text-right whitespace-nowrap"
                            >
                                {item.shares.extensions} €
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </AnalyticsCard>
    );
};
export default SalesSharesCard;
