import { FunctionComponent, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useOperationsPage } from '../context/KPIOperations/OperationsProvider';
import LoadSpinner from '../components/LoadSpinner';
import OvertimeTotalCard from '../components/analytics/cards/OvertimeTotal';
import OvertimeOutletsCard from '../components/analytics/cards/OvertimeOutlets';
import LastAuditCard from '../components/analytics/cards/LastAudit';
import LastFourAuditsCard from '../components/analytics/cards/LastFourMonthAudtis';
import CustomerDiscountShareCard from '../components/analytics/cards/CustomerDiscountShare';
import CustomerDiscountTotalCard from '../components/analytics/cards/CustomerDiscountTotal';
import OutletConsumptionTotalCard from '../components/analytics/cards/OutletConsumptionTotal';
import SalesOutletsPercentAverageCard from '../components/analytics/cards/SalesOutletsPercentAverage';
import HourlyOutputOutletCard from '../components/analytics/cards/HourlyOutputOutlets';
import SicknessQuotaTotalCard from '../components/analytics/cards/SicknessQuotaTotal';
import SicknessQuotaByOutletCard from '../components/analytics/cards/SicknessQuotaByOutlet';

ChartJS.register(LineElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels, annotationPlugin);

const KPIOperations: FunctionComponent = () => {
    const { data, fetchData } = useOperationsPage();

    useEffect(() => {
        fetchData();
    }, []);

    if (!data.wasFetched) {
        return <LoadSpinner />;
    }

    return (
        <>
            <OvertimeTotalCard
                data={data.overtimeTotal.data}
                labels={data.overtimeTotal.labels}
                fetchData={fetchData}
            />
            <OvertimeOutletsCard
                data={data.overtimeByOutlet.data}
                labels={data.overtimeByOutlet.labels}
                fetchData={fetchData}
            />
            <LastAuditCard
                data={data.auditLastGrade.data}
                labels={data.auditLastGrade.labels}
                fetchData={fetchData}
            />
            <LastFourAuditsCard
                data={data.auditLastFourMonth.data}
                labels={data.auditLastFourMonth.labels}
                fetchData={fetchData}
            />

            <CustomerDiscountShareCard
                data={data.customerDiscountShare.data}
                labels={data.customerDiscountShare.labels}
            />

            <CustomerDiscountTotalCard
                data={data.customerDiscountTotal.data}
                labels={data.customerDiscountTotal.labels}
            />

            <OutletConsumptionTotalCard
                data={data.onSiteConsumptionTotal.data}
                labels={data.onSiteConsumptionTotal.labels}
            />

            <SalesOutletsPercentAverageCard
                data={data.onSiteConsumptionByOutlet.data}
                labels={data.onSiteConsumptionByOutlet.labels}
            />

            <HourlyOutputOutletCard
                data={data.hourlyOutputByOutlet.data}
                fetchData={fetchData}
                labels={data.hourlyOutputByOutlet.labels}
            />
            <SicknessQuotaTotalCard
                data={data.sicknessQuotaTotal.data}
                labels={data.sicknessQuotaTotal.labels}
                fetchData={fetchData}
            />
            <SicknessQuotaByOutletCard
                data={data.sicknessQuotaByOutlet.data}
                labels={data.sicknessQuotaByOutlet.labels}
                fetchData={fetchData}
            />
        </>
    );
};

export default KPIOperations;
