import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const RecruitmentCostCard: FunctionComponent<GraphAnalyticsCardProps<IFluctuationPage['recruitingCost']['data']>> = (props) => {
    const { labels, data, fetchData } = props;
    return (
        <AnalyticsCard
            title="Recruiting-Kosten"
            subtitle="Kosten pro Monat"
            tooltip="Angabe in Euro der entstandenen Kosten pro Monat"
            editableConfigIdentifier="recruiting_cost"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data: data.softgarden,
                            label: 'Softgarden',
                            backgroundColor: '#a1c1ce',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                        {
                            data: data.indeed,
                            label: 'Indeed',
                            backgroundColor: '#558ba0',
                            datalabels: {
                                anchor: 'end',
                                align: 'end',
                                offset: -4,
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                    ],
                }}
                options={{
                    plugins: {
                        tooltip: { enabled: false },
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: -4,
                        },
                    },
                    layout: {
                        padding: {
                            top: 5,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default RecruitmentCostCard;
