import {
    Timeslot,
    ReactionTimeResponse,
    DefectQuotaResponse,
    DefectQuotaByStatusResponse,
    DefectQuotaByOrganizationResponse,
} from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '..';
import { getAbsoluteDataMulti, getSingleLineGraph } from '../storedAnalytics';

const fetchDefectQuota = async (token: string, timeslot: Timeslot, number: 1 | 2): Promise<ISingleLineGraph> => {
    const defectQuota = await sendGetRequest<DefectQuotaResponse>(token, `/statistics/average/defectQuota/${number}?timeslot=${timeslot}`);

    return defectQuota.result.reduce(
        (acc, item) => {
            acc.data.push(item.value);
            acc.labels.push(item.label);
            return acc;
        },
        {
            data: new Array<number>(),
            labels: new Array<string>(),
        },
    );
};

export const fetchKpiMroData = async (token: string): Promise<IKpiMroData> => {
    const effectiveness1Request = getAbsoluteDataMulti(token, 'effectiveness_1', Timeslot.twelveWeeks, true);
    const effectiveness2Request = getSingleLineGraph(token, 'effectiveness_2', Timeslot.lastTwelveMonths);

    const defectQuota1Request = fetchDefectQuota(token, Timeslot.twelveWeeks, 1);
    const defectQuota2Request = fetchDefectQuota(token, Timeslot.twelveWeeks, 2);
    const defectQuotaByStatusRequest = sendGetRequest<DefectQuotaByStatusResponse>(
        token,
        '/statistics/average/defectQuotaByStatus?timeslot=twelveWeeks',
    );
    const defectQuotaByOrganizationRequest = sendGetRequest<DefectQuotaByOrganizationResponse>(
        token,
        '/statistics/average/defectQuotaByOrganization?timeslot=twelveWeeks',
    );
    const reactionTimeRequest = sendGetRequest<ReactionTimeResponse>(token, '/statistics/average/reactionTime?timeslot=twelveWeeks');

    const [effectiveness1, effectiveness2, defectQuota1, defectQuota2, defectQuotaByStatus, defectQuotaByOrganization, reactionTime] =
        await Promise.all([
            effectiveness1Request,
            effectiveness2Request,
            defectQuota1Request,
            defectQuota2Request,
            defectQuotaByStatusRequest,
            defectQuotaByOrganizationRequest,
            reactionTimeRequest,
        ]);

    return {
        effectiveness1,
        effectiveness2,
        defectQuotaByOrganization: defectQuotaByOrganization.result,
        defectQuotaByStatus: defectQuotaByStatus.result,
        defectQuota1,
        defectQuota2,
        reactionTime: reactionTime.result,
        wasFetched: true,
    };
};
