import { FunctionComponent, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useFrequencyData } from '../context/KPIMarketing/FrequencyProvider';
import LoadSpinner from '../components/LoadSpinner';
import FrequencyRateCard from '../components/analytics/cards/FrequencyRate';
import ConversationRateCard from '../components/analytics/cards/ConversationRate';
import OriginCard from '../components/analytics/cards/Origin';
import ConversionByChannelCard from '../components/analytics/cards/ConversionByChannel';
import MarketingCostByChannelCard from '../components/analytics/cards/MarketingCostsByChannel';
import ROASByChannelCard from '../components/analytics/cards/ROASByChannel';
import SalesVolumeTotalCard from '../components/analytics/cards/SalesVolumeTotal';
import PaddingQuotientCard from '../components/analytics/cards/PaddingQuotient';
import SalesSharesCard from '../components/analytics/cards/SalesShare';
import NewCustomersCard from '../components/analytics/cards/NewCustomers';
import CustomerAquisitionCostsCard from '../components/analytics/cards/CustomerAquisitionCosts';
import AverageOrderVolumeCard from '../components/analytics/cards/AverageOrderVolume';
import SalesBonCard from '../components/analytics/cards/SalesBon';
import ForecastBookingQuoteCard from '../components/analytics/cards/ForecastBookingQuote';

ChartJS.register(LineElement, BarElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels);

const KPIMarketingAdFrequency: FunctionComponent = () => {
    const { data, fetchData } = useFrequencyData();
    useEffect(() => {
        fetchData();
    }, []);

    return data.wasFetched ? (
        <>
            <FrequencyRateCard
                data={data.websiteVisitors.map((item) => item.value)}
                labels={data.websiteVisitors.map((item) => item.label)}
            />

            <ConversationRateCard
                labels={data.conversionsInPercent.map((item) => item.label)}
                data={{
                    bookingDone: data.conversionsInPercent.map((item) => item.bookingDone),
                    purchase: data.conversionsInPercent.map((item) => item.purchase),
                }}
            />

            <OriginCard
                data={data.sources.map((item) => item.value)}
                labels={data.sources.map((item) => item.label)}
            />

            <ConversionByChannelCard
                data={data.conversionByChannel.data}
                fetchData={fetchData}
                labels={data.conversionByChannel.labels}
            />

            <MarketingCostByChannelCard
                data={data.marketingCostByChannel.data}
                fetchData={fetchData}
                labels={data.marketingCostByChannel.labels}
            />

            <ROASByChannelCard
                data={data.roasByChannel.data}
                fetchData={fetchData}
                labels={data.roasByChannel.labels}
            />

            <SalesVolumeTotalCard
                data={data.revenueTotal}
                labels={data.revenueTotal.map((item) => item.label)}
            />

            <PaddingQuotientCard
                data={data.paddingQuota.map((item) => Math.round(item.value / 1000))}
                labels={data.paddingQuota.map((item) => item.label)}
            />

            <SalesSharesCard data={data.salesShares} />

            <NewCustomersCard
                data={data.newCustomerBookings.map((item) => item.value)}
                labels={data.newCustomerBookings.map((item) => item.label)}
            />

            <CustomerAquisitionCostsCard
                data={data.customerAcquisitionCosts.data}
                fetchData={fetchData}
                labels={data.customerAcquisitionCosts.labels}
            />

            <AverageOrderVolumeCard
                data={data.averageOrderValue.data}
                fetchData={fetchData}
                labels={data.conversionByChannel.labels}
            />

            <SalesBonCard
                data={{
                    consumption: Math.floor(data.bon.consumption / 1000),
                    textiles: Math.floor(data.bon.textiles / 1000),
                    packages: Math.floor(data.bon.packages / 1000),
                    upgrades: Math.floor(data.bon.upgrades / 1000),
                    membercard: Math.floor(data.bon.membercard / 1000),
                    flex: Math.floor(data.bon.flex / 1000),
                    extension: Math.floor(data.bon.extension / 1000),
                }}
            />

            <ForecastBookingQuoteCard
                data={{
                    gainQuotient: data.forecastBookingQuota.gainQuotient,
                    currentQuotient: data.forecastBookingQuota.currentQuotient,
                }}
                labels={data.forecastBookingQuota.labels}
            />
        </>
    ) : (
        <LoadSpinner />
    );
};

export default KPIMarketingAdFrequency;
