/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OutletSalesByWeekResponse, GetOutletCustomerFeedbackResponse, Timeslot } from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '..';
import Feedback from '../../types/kpiQuality/feedbackIds';
import { loadAnalyticsData } from '../storedAnalytics';

const fetchOutletSalesComparisonData = async (token: string): Promise<IOutletSalesComparisonData> => {
    const outletSalesData = await sendGetRequest<OutletSalesByWeekResponse>(token, '/statistics/outletSalesByWeek?timeslot=lastWeek');
    return outletSalesData.result.reduce(
        (acc, curr) => {
            acc.labels.push(curr.displayName);
            acc.data = acc.data.concat(curr.sales);
            return acc;
        },
        { labels: new Array<string>(), data: new Array<number>() },
    );
};

const calculateAverageFeedbackScores = (data: FeedbackItem[]): { averageScores: number[]; counts: number[] } => {
    // accumulate the feedback scores and count how many we got
    const outletMap = data.reduce((acc, curr) => {
        const { outletId, feedbackScore } = curr;
        if (!acc.has(outletId)) {
            acc.set(outletId, { totalScore: 0, count: 0 });
        }
        acc.get(outletId)!.totalScore += feedbackScore;
        acc.get(outletId)!.count += 1;

        return acc;
    }, new Map<number, { totalScore: number; count: number }>());

    return Array.from(outletMap.entries()).reduce(
        (acc, [_, curr]) => {
            const averageScore = curr.totalScore / curr.count;
            acc.averageScores.push(parseFloat(averageScore.toFixed(2)));
            acc.counts.push(curr.count);

            return acc;
        },
        { averageScores: new Array<number>(), counts: new Array<number>() },
    );
};

const fetchOutletCustomerFeedbackData = async (token: string): Promise<IOutletFeedback> => {
    const outletFeedbackData = await sendGetRequest<GetOutletCustomerFeedbackResponse>(
        token,
        '/statistics/outletCustomerFeedback?timeslot=lastWeek',
    );

    const { cleanliness, friendliness, punctuality, labelsSet } = outletFeedbackData.result.reduce(
        (acc, feedback) => {
            const { outletId, feedbackQuestionId, feedbackScore, displayName } = feedback;
            acc.labelsSet.add(displayName);
            const feedbackItem = { outletId, displayName, feedbackScore };
            switch (feedbackQuestionId) {
                case Feedback.CLEANLINESS:
                    acc.cleanliness.push(feedbackItem);
                    break;
                case Feedback.PUNKTUALITY:
                    acc.punctuality.push(feedbackItem);
                    break;
                case Feedback.FRIENDLINESS:
                    acc.friendliness.push(feedbackItem);
                    break;
                default:
                    break;
            }
            return acc;
        },
        {
            cleanliness: new Array<FeedbackItem>(),
            friendliness: new Array<FeedbackItem>(),
            punctuality: new Array<FeedbackItem>(),
            labelsSet: new Set<string>(),
        },
    );

    const { averageScores: cleanlinessScore, counts: cleanlinessCounts } = calculateAverageFeedbackScores(cleanliness);
    const { averageScores: friendlinessScore, counts: friendlinessCounts } = calculateAverageFeedbackScores(friendliness);
    const { averageScores: punctualityScore, counts: punctualityCounts } = calculateAverageFeedbackScores(punctuality);
    const labels: string[] = Array.from(labelsSet);

    const data = {
        cleanliness: cleanlinessScore,
        friendliness: friendlinessScore,
        punctuality: punctualityScore,
    };
    const counts = {
        cleanliness: cleanlinessCounts,
        friendliness: friendlinessCounts,
        punctuality: punctualityCounts,
    };

    return {
        data,
        labels,
        counts,
    };
};

const fetchOutletComparisonData = async (token: string): Promise<IOutletComparisonData> => {
    const [outletSalesData, outletFeedbackData, analytics1] = await Promise.all([
        fetchOutletSalesComparisonData(token),
        fetchOutletCustomerFeedbackData(token),
        loadAnalyticsData(token, ['productivity', 'resignation_in_probationary'], Timeslot.fourWeeks),
    ]);

    const [productivity, resignationInProbationary] = analytics1.map((response) => {
        const result = response.result.reduce(
            (acc, analyticsData) => {
                acc.labels.push(`KW: ${analyticsData.interval}`);
                analyticsData.values.forEach((value) => {
                    if (!acc.data[value.identifier]) {
                        acc.data[value.identifier] = [];
                    }
                    acc.data[value.identifier].push({ kw: `KW: ${analyticsData.interval}`, value: value.value });
                });
                return acc;
            },
            { labels: [], data: {} } as GraphDataPerOutlet,
        );
        return result;
    });
    return {
        outletSales: outletSalesData,
        outletFeedback: outletFeedbackData,
        productivity,
        resignationInProbationary,
        wasFetched: true,
    };
};

export default fetchOutletComparisonData;
