import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const LastFourMonthsAuditsCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        currentMonth: number[];
        currentMinusOne: number[];
        currentMinusTwo: number[];
        currentMinusThree: number[];
    }>
> = ({ data, fetchData, labels }) => {
    // Audits in den letzen 4 Monaten - Bar chart
    const dataLast4MonthsAudits: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: '-3 Monate',
                data: data.currentMinusThree,
                backgroundColor: ['#e6765a', '#558ba0e6', '#edcd77e6'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: '-2 Monate',
                data: data.currentMinusTwo,
                backgroundColor: ['#e6765a4d', '#558ba04d', '#edcd774d'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: '-1 Monate',
                data: data.currentMinusOne,
                backgroundColor: ['#e6765a99', '#558ba099', '#edcd7799'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Aktueller Monat',
                data: data.currentMonth,
                backgroundColor: ['#e6765a', '#558ba0', '#edcd77'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsLast4MonthsAudits: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Audit"
            subtitle="letzte 4 Monate jeder Standort"
            editableConfigIdentifier="audit_grade"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataLast4MonthsAudits}
                options={optionsLast4MonthsAudits}
            />
        </AnalyticsCard>
    );
};
export default LastFourMonthsAuditsCard;
