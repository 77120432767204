import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const PersonalCostsToRevenueCard: FunctionComponent<GraphAnalyticsCardProps> = (props) => {
    const { labels, data, fetchData } = props;

    return (
        <AnalyticsCard
            title="PeKo in % zum Umsatz"
            subtitle=""
            tooltip="Personalkosten inkl. ext. PeKo / Umsatz"
            editableConfigIdentifier="personal_costs_to_revenue"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            data,
                            datalabels: {
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'white',
                            },
                        },
                    ],
                }}
                options={{
                    plugins: {
                        tooltip: { enabled: false },
                        legend: {
                            display: false,
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        annotation: {
                            annotations: {
                                line1: {
                                    type: 'line',
                                    yMin: 24,
                                    yMax: 24,
                                    borderColor: '#eb6253',
                                    borderDash: [10, 10],
                                },
                                label1: {
                                    type: 'label',
                                    xValue: 0.5,
                                    yValue: 24 - 4,
                                    content: ['Zielsetzung'],
                                    color: '#eb6253',
                                },
                            },
                        },
                    },
                    layout: {
                        padding: {
                            top: 5,
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default PersonalCostsToRevenueCard;
