import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Pie } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OriginCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels }) => {
    // Conversions - Pie Chart
    const dataOrigin: ChartData<'pie', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: ['#e6765a', '#bac6bd', '#90AF76', '#edcd77', '#558ba0'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsOrigin: ChartOptions<'pie'> = {
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: 14,
                    },
                },
            },
            datalabels: {
                anchor: 'center',
                align: 'end',
                offset: 35,
            },
        },
        layout: {
            padding: {
                top: 30,
                bottom: 30,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Herkunft"
            subtitle="Website-Besucher absolut nach Kanal | in %"
            live
            tooltip="Übersicht nach Herkunft der Website-Besucher"
        >
            <Pie
                data={dataOrigin}
                options={optionsOrigin}
            />
        </AnalyticsCard>
    );
};

export default OriginCard;
