import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { getColorForIndex } from '../../../util';

const OutletProductivity: FunctionComponent<GraphAnalyticsCardProps<StoredAnalyticsDataRecord>> = ({ labels, data, fetchData }) => {
    const outletProductivityData: ChartData<'line', { kw: string; value: number }[]> = {
        labels,
        datasets: Object.entries(data).map(([outletId, outletData], idx) => ({
            label: outletId,
            data: outletData.map((dataPoint) => dataPoint),
            backgroundColor: getColorForIndex(idx),
            fill: false,
            borderColor: getColorForIndex(idx),
            tension: 0.4,
        })),
    };

    const outletProductivityOptions: ChartOptions<'line'> = {
        parsing: {
            xAxisKey: 'kw',
            yAxisKey: 'value',
        },
        plugins: {
            tooltip: { enabled: true },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
                display: false,
                formatter(value) {
                    return `${value.value}`;
                },
            },
        },
        layout: {
            autoPadding: true,
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Produktivität"
            subtitle="der Filialen"
            tooltip="Verkäufen in der Filiale * Wegelängenfaktor / eingesetzte Personalstunden * stattgefundene Aufenthalte * 100"
            onSaveEditableConfig={fetchData && fetchData}
            editableConfigIdentifier="productivity"
        >
            <Line
                data={outletProductivityData}
                options={outletProductivityOptions}
            />
        </AnalyticsCard>
    );
};

export default OutletProductivity;
