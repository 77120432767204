import { FunctionComponent, useEffect, useState } from 'react';
import rankUp from '../icons/icon__rank-up.svg';
import rankDown from '../icons/icon__rank-down.svg';
import rankEqual from '../icons/icon__rank-equal.svg';

type Ranktype = {
    direction: 'up' | 'down' | 'equal';
};

const RankIndicator: FunctionComponent<Ranktype> = ({ direction }) => {
    const [indicationClassName, setIndicationClassName] = useState('self-start');
    const [animationClassName, setAnimationClassName] = useState('animate-bounce-up');
    const [rankIcon, setRankIcon] = useState(rankUp);

    useEffect(() => {
        if (direction === 'down') {
            setIndicationClassName('self-end');
            setAnimationClassName('animate-bounce-down');
            setRankIcon(rankDown);
        } else if (direction === 'equal') {
            setIndicationClassName('self-center');
            setAnimationClassName('');
            setRankIcon(rankEqual);
        }
    });

    return (
        <div className="flex relative px-4 h-full">
            <div className="absolute right-1/2 left-1/2 bg-dark-chocolate w-0.5 h-full" />
            <img
                className={`w-10 h-10 min-w-[2.5rem] shadow-md rounded-full z-10 ${indicationClassName} ${animationClassName}`}
                src={rankIcon}
                alt=""
            />
        </div>
    );
};

export default RankIndicator;
