/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    BookingWeeklyResponse,
    GoogleAnalyticsConversionsResponse,
    GoogleAnalyticsVisitorSourcesResponse,
    NewCustomerBookingsResponse,
    RevenueBonResponse,
    RevenuePaddingQuotaResponse,
    SalesSharesByCalendarWeekResponse,
    Timeslot,
    WebsiteVisitorsResponse,
    RevenueTotalResponse,
} from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '..';
import { getMultiLineGraphData, getSingleLineGraph } from '../storedAnalytics';
import { formatNumber } from '../../util';

const fetchSourcesData = async (token: string): Promise<Pick<IFrequencyData, 'sources'>> => {
    const sourcesResponse = await sendGetRequest<GoogleAnalyticsVisitorSourcesResponse>(
        token,
        '/integrations/googleAnalytics/visitorSources?timeslot=twelveWeeks',
    );

    const sourcesMap = sourcesResponse.result.reduce((acc, member) => {
        member.sources.forEach((source) => {
            const sourceVisitors = acc.get(source.source) || 0;
            acc.set(source.source, sourceVisitors + source.visitors);
        });

        return acc;
    }, new Map<string, number>());

    const totalConversion: number = Array.from(sourcesMap.values()).reduce((acc, visitors) => acc + visitors, 0);

    return {
        sources: Array.from(sourcesMap.entries()).map(([source, visitors]) => ({
            label: source,
            value: Math.floor((visitors / totalConversion) * 100),
        })),
    };
};

export const fetchFrequencyData = async (token: string): Promise<IFrequencyData> => {
    const websiteVisitorsRequest = sendGetRequest<WebsiteVisitorsResponse>(
        token,
        '/integrations/googleAnalytics/websiteVisitors?timeslot=twelveWeeks',
    );

    const conversionsRequest = sendGetRequest<GoogleAnalyticsConversionsResponse>(
        token,
        '/integrations/googleAnalytics/conversions?timeslot=twelveWeeks',
    );

    const paddingQuotaRequest = sendGetRequest<RevenuePaddingQuotaResponse>(token, `/statistics/revenue/padding?timeslot=twelveWeeks`);

    const revenueTotalRequest = sendGetRequest<RevenueTotalResponse>(token, '/statistics/revenue/total?timeslot=twelveWeeks');
    const salesSharesRequest = sendGetRequest<SalesSharesByCalendarWeekResponse>(
        token,
        '/statistics/salesSharesByCalendarWeek?timeslot=fourWeeks',
    );

    const newCustomerBookingsRequest = sendGetRequest<NewCustomerBookingsResponse>(
        token,
        `/statistics/newCustomerBookings?timeslot=twelveWeeks`,
    );

    const revenueBonRequest = sendGetRequest<RevenueBonResponse>(token, '/statistics/revenue/bon?timeslot=fourWeeks');

    const customerAcquisitionCostsRequest = getSingleLineGraph(token, 'customer_aquisition_costs', Timeslot.lastTwelveMonths);

    const averageOrderValueRequest = getSingleLineGraph(token, 'average_order_volume', Timeslot.lastTwelveMonths);

    const forecastBookingQuotaRequest = sendGetRequest<BookingWeeklyResponse>(
        token,
        '/statistics/bookingWeeklyComparison?timeslot=fourWeeks',
    );

    const [
        websiteVisitors,
        conversions,
        revenueTotal,
        paddingQuota,
        salesShares,
        newCustomerBookings,
        customerAcquisitionCosts,
        averageOrderValue,
        revenueBon,
        forecastBookingQuota,
        sources,
    ] = await Promise.all([
        websiteVisitorsRequest,
        conversionsRequest,
        revenueTotalRequest,
        paddingQuotaRequest,
        salesSharesRequest,
        newCustomerBookingsRequest,
        customerAcquisitionCostsRequest,
        averageOrderValueRequest,
        revenueBonRequest,
        forecastBookingQuotaRequest,
        fetchSourcesData(token),
    ]);

    const conversionByChannel = await getMultiLineGraphData(token, 'conversions_by_channel', Timeslot.twelveWeeks);

    const roasByChannel = await getMultiLineGraphData(token, 'roas_by_channel', Timeslot.twelveWeeks);

    const marketingCostByChannel = await getMultiLineGraphData(token, 'marketing_cost_by_channel', Timeslot.fourWeeks);

    return {
        websiteVisitors: websiteVisitors.result.map((member) => ({
            value: parseInt((member.visitors / 1000).toString(), 10),
            label: `KW ${member.calendarWeek}`,
        })),
        conversionsInPercent: conversions.result.map((member) => ({
            label: `KW ${member.calendarWeek}`,
            purchase: parseFloat(Math.floor(member.purchase * 10000).toString()) / 100,
            bookingDone: parseFloat(Math.floor(member.bookingDone * 10000).toString()) / 100,
        })),
        conversionByChannel,
        roasByChannel,
        marketingCostByChannel,
        revenueTotal: revenueTotal.result.map((member) => ({
            salesOrder: parseInt(member.salesOrder.toString(), 10),
            bookings: parseInt(member.bookings.toString(), 10),
            outOfHouseSales: parseInt(member.outOfHouseSales.toString(), 10),
            label: `KW ${member.calendarWeek}`,
        })),
        paddingQuota: paddingQuota.result.map((member) => ({
            value: parseInt(member.score.toString(), 10),
            label: `KW ${member.calendarWeek}`,
        })),
        salesShares: salesShares.result.map((member) => ({
            calendarWeek: `KW ${member.calendarWeek}`,
            shares: {
                wellnessHours: formatNumber(member.salesShares.wellnessHours, {
                    decimalPlaces: 0,
                    useThousandsSeparator: true,
                    thousandSeparator: '.',
                }),
                packages: formatNumber(member.salesShares.packages, {
                    decimalPlaces: 0,
                    useThousandsSeparator: true,
                    thousandSeparator: '.',
                }),
                birthdayUpgrade: formatNumber(member.salesShares.birthdayUpgrade, {
                    decimalPlaces: 0,
                    useThousandsSeparator: true,
                    thousandSeparator: '.',
                }),
                candlelightUpgrade: formatNumber(member.salesShares.candlelightUpgrade, {
                    decimalPlaces: 0,
                    useThousandsSeparator: true,
                    thousandSeparator: '.',
                }),
                flex: formatNumber(member.salesShares.flex, {
                    decimalPlaces: 0,
                    useThousandsSeparator: true,
                    thousandSeparator: '.',
                }),
                extensions: formatNumber(member.salesShares.extensions, {
                    decimalPlaces: 0,
                    useThousandsSeparator: true,
                    thousandSeparator: '.',
                }),
            },
        })),
        newCustomerBookings: newCustomerBookings.result.map((member) => ({
            value: parseInt((member.score * 100).toString(), 10),
            label: `KW ${member.calendarWeek}`,
        })),
        customerAcquisitionCosts,
        averageOrderValue,
        bon: revenueBon.result,
        forecastBookingQuota: {
            labels: forecastBookingQuota.result.map((member) => `KW ${member.calendarWeek}`),
            gainQuotient: forecastBookingQuota.result.map((member) => member.createdBookings),
            currentQuotient: forecastBookingQuota.result.map((member) => member.completedBookings),
        },
        ...sources,
        wasFetched: true,
    };
};
