import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { TagComparisonData } from '../../../types/kpiAudit/auditAnalytics';

const LastAuditTagComparison: FunctionComponent<GraphAnalyticsCardProps<TagComparisonData>> = ({ data, labels }) => {
    const lastTagsData: ChartData<'bar', (number | null)[]> = {
        labels,
        datasets: Object.entries(data.data).map(([key, value], idx) => ({
            label: key,
            data: value,
            backgroundColor: ['#a1c1ce', '#e6765a', '#edcd77', '#558ba0', '#90AF76'][idx],
        })),
    };

    const lastTagsOptions: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: true },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: { display: false },
        },
        parsing: {
            yAxisKey: 'rating',
            xAxisKey: 'outlet',
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Tag Vergleich vom letzten Audit"
            tooltip="Die Bewertungen des letzen Audits aus jeder Filiale, aufgeteilt in die Tags"
        >
            <Bar
                data={lastTagsData}
                options={lastTagsOptions}
            />
        </AnalyticsCard>
    );
};

export default LastAuditTagComparison;
