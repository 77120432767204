import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useRoutes } from '../../context/RouteProvider';

const DotNavIndicator: FunctionComponent = () => {
    const { routes, setCurrentRoute } = useRoutes();

    return (
        <div className="md:sticky flex items-center justify-center flex-wrap px-4 pt-4 pb-8 bottom-0 gap-4 bg-beige">
            {routes.map((route, index) => (
                <NavLink
                    className="navlink"
                    id={route.path}
                    to={route.path}
                    key={`route_${route.path}}`}
                    onClick={(): void => {
                        setCurrentRoute(route);
                    }}
                    end
                >
                    {index + 1}
                </NavLink>
            ))}
        </div>
    );
};

export default DotNavIndicator;
