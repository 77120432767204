import { FunctionComponent } from 'react';
import { Pie } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const SubscribersToFriendsCard: FunctionComponent<GraphAnalyticsCardProps<number>> = ({ data }) => {
    return (
        <AnalyticsCard
            title="Subscribers vs. Friends"
            tooltip="Newsletter Empfänger, die Friends geworden sind"
            subtitle="in Prozent"
            live
        >
            <Pie
                data={{
                    labels: ['Subscribers', 'Friends'],
                    datasets: [
                        {
                            data: [100 - data, data],
                            backgroundColor: ['#e6765a', '#bac6bd'],
                            datalabels: {
                                font: {
                                    weight: 'bold',
                                    size: 18,
                                },
                                color: 'black',
                            },
                        },
                    ],
                }}
                options={{
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                font: {
                                    size: 14,
                                },
                            },
                        },
                        datalabels: {
                            anchor: 'center',
                            align: 'end',
                            offset: 35,
                        },
                    },
                    layout: {
                        padding: {
                            top: 30,
                            bottom: 30,
                        },
                    },
                    maintainAspectRatio: false,
                }}
            />
        </AnalyticsCard>
    );
};

export default SubscribersToFriendsCard;
