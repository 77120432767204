import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OutletSalesComparisonCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels }) => {
    // Umsatzvergleich der letzten Woche aller Filialen - Bar chart
    const dataOutletSalesValues = data;
    const dataOutletSalesAverage = data.reduce((a, b) => a + b, 0) / data.length;
    const dataOutletSales: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data: dataOutletSalesValues,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const dataOutletSalesOptions: ChartOptions<'bar'> = {
        plugins: {
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: dataOutletSalesAverage,
                        yMax: dataOutletSalesAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: dataOutletSalesAverage + 40,
                        content: [`Durchschnitt: ${dataOutletSalesAverage.toFixed(1)}`],
                        color: 'gray',
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
                display: true,
            },
            y: {
                beginAtZero: true,
                display: false,
            },
        },
    };

    return (
        <AnalyticsCard
            title="Verkaufsvergleich"
            subtitle="Verkaufsvergleich aller Filialen | in Tsd."
            live
        >
            <Bar
                data={dataOutletSales}
                options={dataOutletSalesOptions}
            />
        </AnalyticsCard>
    );
};

export default OutletSalesComparisonCard;
