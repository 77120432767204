import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import fetchOperationsPage from '../../api/kpiOperations/operations';

const initialData: IOperationsData = {
    overtimeTotal: {
        labels: [],
        data: [],
    },
    overtimeByOutlet: {
        labels: [],
        data: [],
    },
    auditLastGrade: {
        labels: [],
        data: [],
    },
    auditLastFourMonth: {
        labels: [],
        data: {
            currentMonth: [],
            currentMinusOne: [],
            currentMinusTwo: [],
            currentMinusThree: [],
        },
    },
    hourlyOutputByOutlet: {
        labels: [],
        data: [],
    },
    sicknessQuotaTotal: {
        labels: [],
        data: [],
    },

    sicknessQuotaByOutlet: {
        labels: [],
        data: [],
    },
    customerDiscountShare: {
        labels: [],
        data: [],
    },
    customerDiscountTotal: {
        labels: [],
        data: [],
    },
    onSiteConsumptionByOutlet: {
        labels: [],
        data: [],
    },
    onSiteConsumptionTotal: {
        labels: [],
        data: [],
    },
    wasFetched: false,
};

const OperationsPageContext = ProviderBuilder.buildContext<IOperationsData>();

const OperationsPageProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const operationsPageValue = ProviderBuilder.buildProvider(initialData, fetchOperationsPage);

    return <OperationsPageContext.Provider value={operationsPageValue}>{children}</OperationsPageContext.Provider>;
};

const useOperationsPage = ProviderBuilder.buildUseHook(OperationsPageContext, 'useOperationsPage');

export default OperationsPageProvider;
export { useOperationsPage, OperationsPageContext };
