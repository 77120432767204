/* eslint-disable no-param-reassign */
import {
    AgeDistributionResponse,
    GenderDistributionResponse,
    NpsScoreByMonthResponse,
    NpsTrendByCalendarWeekResponseWithOutlet,
    NpsTrendByCalendarWeekResponseWithoutOutlet,
    GeneralNumberResponse,
    Timeslot,
} from '@mywellness-gmbh/backend-types';
import { sendGetRequest } from '.';
import { getSingleLineGraph, loadAnalyticsData } from './storedAnalytics';
import { clamp } from '../util';

const fetchCustomerAgeData = async (token: string): Promise<ICustomerAge> => {
    const ageData = await sendGetRequest<AgeDistributionResponse>(token, `/statistics/average/age?timeslot=fourWeeks`);

    return {
        averageFemale: ageData.result.female,
        averageMale: ageData.result.male,
    };
};

const fetchCustomerGenderData = async (token: string): Promise<ICustomerGender> => {
    const genderData = await sendGetRequest<GenderDistributionResponse>(token, `/statistics/average/genderDistribution?timeslot=lastMonth`);

    return {
        percentFemale: genderData.result.female * 100,
        percentMale: genderData.result.male * 100,
    };
};

const fetchCustomerStayDuration = async (token: string): Promise<IAverageStay> => {
    const avgStayThisWeek = await sendGetRequest<GeneralNumberResponse>(token, `/statistics/average/stayDuration?timeslot=thisWeek`);

    const avgStayLastWeek = await sendGetRequest<GeneralNumberResponse>(token, `/statistics/average/stayDuration?timeslot=lastWeek`);

    let rankIndicator: RankIndicator = 'equal';
    if (avgStayThisWeek.result > avgStayLastWeek.result) {
        rankIndicator = 'up';
    } else if (avgStayThisWeek.result < avgStayLastWeek.result) {
        rankIndicator = 'down';
    }

    return {
        averageThisWeek: avgStayThisWeek.result * 60,
        rankIndicator,
        averageLastWeek: avgStayLastWeek.result * 60,
    };
};

const fetchCustomerPersonsPerBooking = async (token: string): Promise<IAveragePersons> => {
    const personsPerBookingThisWeek = await sendGetRequest<GeneralNumberResponse>(
        token,
        `/statistics/average/personsPerBooking?timeslot=thisWeek`,
    );

    const personsPerBookingLastWeek = await sendGetRequest<GeneralNumberResponse>(
        token,
        `/statistics/average/personsPerBooking?timeslot=lastWeek`,
    );

    let rankIndicator: RankIndicator = 'equal';
    if (personsPerBookingThisWeek.result > personsPerBookingLastWeek.result) {
        rankIndicator = 'up';
    } else if (personsPerBookingThisWeek.result < personsPerBookingLastWeek.result) {
        rankIndicator = 'down';
    }

    return {
        averageThisWeek: personsPerBookingThisWeek.result,
        rankIndicator,
        averageLastWeek: personsPerBookingLastWeek.result,
    };
};

const fetchGoogleRating = async (token: string): Promise<IGoogleRating> => {
    const data = await loadAnalyticsData(token, 'google_rating', Timeslot.fourWeeks);
    const lastWeek = data.result[data.result.length - 1]?.values[0]?.value || 0;
    const preLastWeek = data.result[data.result.length - 2]?.values[0]?.value || 0;
    const differenceinPercent = Math.floor(((lastWeek - preLastWeek) / preLastWeek) * 100) || 0;

    let indicator: 'up' | 'down' | 'equal' = 'up';
    let formatted = `+ ${differenceinPercent}%`;
    if (differenceinPercent < 0) {
        indicator = 'down';
        formatted = `- ${Math.abs(differenceinPercent)}%`;
    } else if (differenceinPercent === 0) {
        indicator = 'equal';
        formatted = 'Keine Veränderung';
    }

    return {
        differenceToPreLastWeek: formatted,
        lastWeek,
        differenceToPreLastWeekIndicator: indicator,
    };
};

/**
 * Fetches the customer data for the given outlet
 *
 * @param token The token to use for the request
 * @param outletId The id of the outlet to fetch the data for
 * @returns A promise that resolves to the customer data for the given outlet
 */
export const fetchCustomerData = async (token: string): Promise<ICustomerData> => {
    const ageDataRequest = fetchCustomerAgeData(token);
    const genderDataRequest = fetchCustomerGenderData(token);
    const stayDurationRequest = fetchCustomerStayDuration(token);
    const personsPerBookingRequest = fetchCustomerPersonsPerBooking(token);
    const googleRatingRequest = fetchGoogleRating(token);
    const trendRequest = sendGetRequest<NpsScoreByMonthResponse>(token, '/statistics/nps/trend/perMonth');
    const npsTotalRequest = sendGetRequest<NpsTrendByCalendarWeekResponseWithoutOutlet>(
        token,
        '/statistics/nps/trend/perWeek?timeslot=twelveWeeks',
    );

    const npsByOutletRequest = sendGetRequest<NpsTrendByCalendarWeekResponseWithOutlet>(
        token,
        '/statistics/nps/trend/perWeek/byOutlet?timeslot=thisWeek',
    );

    const crmProductivityRequest = await getSingleLineGraph(token, 'crm_productivity', Timeslot.twelveWeeks);
    const npsBookingsRequest = await sendGetRequest<NpsScoreByMonthResponse>(token, '/statistics/nps/bookings?timeslot=lastTwelveMonths');

    const [ageData, genderData, stayDuration, personsPerBooking, googleRating, trend, npsTotal, npsByOutlet, crmProductivity, npsBookings] =
        await Promise.all([
            ageDataRequest,
            genderDataRequest,
            stayDurationRequest,
            personsPerBookingRequest,
            googleRatingRequest,
            trendRequest,
            npsTotalRequest,
            npsByOutletRequest,
            crmProductivityRequest,
            npsBookingsRequest,
        ]);

    return {
        trend: trend.result.map((item) => ({
            score: item.score,
            label: item.created,
        })),
        age: ageData,
        gender: genderData,
        averageStay: stayDuration,
        averagePersons: personsPerBooking,
        googleRating,
        npsTotal: npsTotal.result.map((item) => ({
            label: `KW ${item.calendarWeek}`,
            value: Math.round(item.score),
        })),
        npsByOutlet: npsByOutlet.result.map((item) => ({
            outletId: item.outletId,
            displayName: item.displayName,
            value: Math.round(item.scores[0]?.score || 0),
        })),
        crmProductivity,
        npsBookings: {
            labels: npsBookings.result.map((month) => month.created),
            data: npsBookings.result.map((month) => clamp(Math.round(month.score), 0, 100)),
        },
        wasFetched: true,
    };
};
