import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const Effectiveness1Card: FunctionComponent<GraphAnalyticsCardProps> = ({ data, fetchData, labels }) => {
    // Effektivitiät in eingereichte Std - Line Chart
    const dataEffectiveness1: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsEffectiveness1: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Effektivität 1"
            subtitle="Eingereichte Std. von MRO | in Std."
            editableConfigIdentifier="effectiveness_1"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataEffectiveness1}
                options={optionsEffectiveness1}
            />
        </AnalyticsCard>
    );
};

export default Effectiveness1Card;
