import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchKpiMroData } from '../../api/kpiMro/kpiMro';

const initialData: IKpiMroData = {
    effectiveness1: {
        labels: [],
        data: [],
    },
    effectiveness2: {
        labels: [],
        data: [],
    },
    defectQuota1: {
        labels: [],
        data: [],
    },
    defectQuota2: {
        labels: [],
        data: [],
    },
    defectQuotaByStatus: [],
    defectQuotaByOrganization: [],
    reactionTime: {
        labels: [],
        pending: [],
        open: [],
        closed: [],
    },
    wasFetched: false,
};

const KpiMroDataContext = ProviderBuilder.buildContext<IKpiMroData>();

const KpiMroDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiMroDataValue = ProviderBuilder.buildProvider<IKpiMroData>(initialData, fetchKpiMroData);

    return <KpiMroDataContext.Provider value={kpiMroDataValue}>{children}</KpiMroDataContext.Provider>;
};

const useKpiMroData = ProviderBuilder.buildUseHook(KpiMroDataContext, 'useKpiMroData');

export default KpiMroDataProvider;
export { useKpiMroData, KpiMroDataContext };
