import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchCustomerExpAuditAnalyticsData } from '../../api/kpiQM/auditAnalytics';
import { IAuditAnalytics } from '../../types/kpiAudit/auditAnalytics';

const initialData: IAuditAnalytics = {
    lastOutletScores: [],
    outletScoresByTag: {},
    tagScoresByOutlet: {},
    lastAuditTagComparison: { labels: [], data: {} },
    wasFetched: false,
};

const CustomerExpAuditAnalyticsContext = ProviderBuilder.buildContext<IAuditAnalytics>();

const CustomerExpAuditAnalyticsDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const CustomerExpAuditAnalyticsDataValue = ProviderBuilder.buildProvider<IAuditAnalytics>(
        initialData,
        fetchCustomerExpAuditAnalyticsData,
    );

    return (
        <CustomerExpAuditAnalyticsContext.Provider value={CustomerExpAuditAnalyticsDataValue}>
            {children}
        </CustomerExpAuditAnalyticsContext.Provider>
    );
};

const useCustomerExpAuditAnalyticsData = ProviderBuilder.buildUseHook(CustomerExpAuditAnalyticsContext, 'useCustomerExpAuditAnalyticsData');

export default CustomerExpAuditAnalyticsDataProvider;
export { useCustomerExpAuditAnalyticsData, CustomerExpAuditAnalyticsContext };
