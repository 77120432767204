import { FunctionComponent, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Analytics from './components/analytics/Analytics';
import Header from './components/Header';
import LoginModal from './components/modal/LoginModal';
import DotNavIndicator from './components/navigation/DotNavIndicator';
import { useRoutes } from './context/RouteProvider';
import { useUser } from './context/UserProvider';
import './styles/global.scss';

const App: FunctionComponent = () => {
    const { user } = useUser();
    const { routes, currentRoute, nextRoute } = useRoutes();

    useEffect(() => {
        if (!user) {
            // force login
            console.error('User not logged in');
        }
    }, [user]);

    useEffect(() => {
        nextRoute.useHook?.fetchData();
    }, [currentRoute]);

    return (
        <>
            {user ? '' : <LoginModal />}
            <div className="App grid grid-rows-[min-content_1fr_min-content] h-full gap-8">
                <Header title="Analytics" />
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to={currentRoute.path} />}
                    />
                    {routes.map((route) => (
                        <Route
                            path={route.path}
                            element={
                                <Analytics
                                    title={currentRoute.title}
                                    subtitle={currentRoute.subtitle}
                                >
                                    {route.component}
                                </Analytics>
                            }
                            key={route.path}
                        />
                    ))}
                    <Route
                        path="*"
                        element={<Navigate to={routes[0].path} />}
                    />
                </Routes>
                <DotNavIndicator />
            </div>
        </>
    );
};

export default App;
