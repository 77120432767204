import { FunctionComponent } from 'react';
import { ChartData, ChartOptions, Point } from 'chart.js';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const SalesVolumeTotalCard: FunctionComponent<
    GraphAnalyticsCardProps<
        {
            salesOrder: number;
            bookings: number;
            outOfHouseSales: number;
        }[]
    >
> = ({ data, labels }) => {
    // Buchungsumsatz gesamt - Line Chart
    const dataSalesVolumeTotal: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Buchungsumsatz',
                data: data.map((item) => Math.round(item.bookings / 1000)),
                borderColor: '#558ba0',
                backgroundColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Sales Order',
                data: data.map((item) => Math.round(item.salesOrder / 1000)),
                borderColor: '#edcd77',
                backgroundColor: '#edcd77',
                pointBackgroundColor: '#edcd77',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Außer-Haus-Verkauf',
                data: data.map((item) => Math.round(item.outOfHouseSales / 1000)),
                borderColor: '#eb6253',
                backgroundColor: '#eb6253',
                pointBackgroundColor: '#eb6253',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsSalesVolumeTotal: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        layout: {
            padding: {
                top: 25,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Buchungsumsatz gesamt"
            subtitle="in Tsd. €"
            live
            tooltip="Umsatz des Vorortverzehrs (sales_order) + Umsatz Buchungen (aus der sales_booking)"
        >
            <Line
                data={dataSalesVolumeTotal}
                options={optionsSalesVolumeTotal}
            />
        </AnalyticsCard>
    );
};
export default SalesVolumeTotalCard;
