import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const ConversationRateCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        bookingDone: number[];
        purchase: number[];
    }>
> = ({ data, labels }) => {
    // Conversion - Line Chart
    const dataConversion: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data: data.bookingDone,
                borderColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
                label: 'Buchung',
            },
            {
                data: data.purchase,
                borderColor: '#bac6bd',
                pointBackgroundColor: '#bac6bd',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
                label: 'Kauf',
            },
        ],
    };

    const optionsConversion: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Conversion Rate"
            subtitle="in %"
            live
            tooltip="Anteil der Website-Besucher, die gekauft haben in %"
        >
            <Line
                data={dataConversion}
                options={optionsConversion}
            />
        </AnalyticsCard>
    );
};

export default ConversationRateCard;
