import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { getColorForIndex } from '../../../util';

const ResignationInProbationary: FunctionComponent<GraphAnalyticsCardProps<StoredAnalyticsDataRecord>> = ({ labels, data, fetchData }) => {
    const resignationData: ChartData<'line', { kw: string; value: number }[]> = {
        labels,
        datasets: Object.entries(data).map(([outletId, outletData], idx) => ({
            label: outletId,
            data: outletData.map((dataPoint) => dataPoint),
            backgroundColor: getColorForIndex(idx),
            fill: false,
            borderColor: getColorForIndex(idx),
            tension: 0.4,
        })),
    };

    const resignationOptions: ChartOptions<'line'> = {
        parsing: {
            xAxisKey: 'kw',
            yAxisKey: 'value',
        },
        plugins: {
            tooltip: { enabled: true },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
                display: false,
                formatter(value) {
                    return `${value.value}`;
                },
            },
        },
        layout: {
            autoPadding: true,
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Kündigungsquote in der Probezeit"
            subtitle="der Filialen"
            tooltip="Alle Kündigungen durch einen Mitarbeiter während der Probezeit / alle Kündigungen in der selben Zeit"
            onSaveEditableConfig={fetchData && fetchData}
            editableConfigIdentifier="resignation_in_probationary"
        >
            <Line
                data={resignationData}
                options={resignationOptions}
            />
        </AnalyticsCard>
    );
};

export default ResignationInProbationary;
