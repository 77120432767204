import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { arrayAverage } from '../../../util';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const CustomerDiscountShareCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels }) => {
    // Kundenrabatte vor Ort - Bar chart
    const dataCustomerDiscountAverage = arrayAverage(data);

    const dataCustomerDiscount: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Previous',
                data,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsCustomerDiscount: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: dataCustomerDiscountAverage,
                        yMax: dataCustomerDiscountAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: dataCustomerDiscountAverage + 0.6,
                        content: [`Durchschnitt: ${dataCustomerDiscountAverage.toFixed(1)}`],
                        color: 'gray',
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Anteile der Kundenrabatte"
            subtitle="vor Ort | in %"
            live
            tooltip="Anteil der vergebenen Kundenrabatte pro Filiale für die letzte Kalenderwoche"
        >
            <Bar
                data={dataCustomerDiscount}
                options={optionsCustomerDiscount}
            />
        </AnalyticsCard>
    );
};
export default CustomerDiscountShareCard;
