import { FunctionComponent } from 'react';
import Modal from './Modal';
import infoIcon from '../../icons/icon__info.svg';
import penIcon from '../../icons/icon__pen.svg';

type Props = {
    closeModal: () => void;
    children: React.ReactNode;
    live: boolean;
    title: string;
    subtitle?: string;
    editable?: boolean;
    tooltip?: string;
    icon?: string;
};

const ChartDetailModal: FunctionComponent<Props> = ({ closeModal, children, live, title, subtitle, editable, tooltip, icon }) => {
    return (
        <Modal
            onClose={closeModal}
            fullscreen
        >
            <aside className="flex items-center gap-x-6 max-sm:mb-4 duration-500 transition-opacity">
                {editable && (
                    <button
                        type="button"
                        className="duration-500 transition-opacity"
                    >
                        <img
                            src={penIcon}
                            alt="Pen Icon"
                            className="h-6"
                        />
                    </button>
                )}

                <div className={`relative group w-8 h-8 p-0.5 rounded-full  ${live || editable ? 'bg-green-apple' : 'bg-danger'}`}>
                    {(live || editable) && (
                        <div className="absolute top-5 right-6 invisible group-hover:visible w-48 px-2 py-1 rounded z-10 bg-white shadow-sm opacity-0 group-hover:opacity-100">
                            <p>{tooltip || `${title} ${subtitle || ''}`}</p>
                        </div>
                    )}
                    <img
                        src={infoIcon}
                        alt="info icon"
                    />
                </div>
            </aside>

            <header className="card-header">
                <div>
                    <h2 className="inline-block mb-6 font-bold text-5xl uppercase tracking-wider break-word">
                        <img
                            className="inline-block h-8 -translate-y-1"
                            src={icon}
                            alt=""
                        />
                        {title}
                    </h2>
                </div>
                {subtitle && <p className="text-center text-3xl">{subtitle}</p>}
            </header>

            <div>{children}</div>
        </Modal>
    );
};

export default ChartDetailModal;
