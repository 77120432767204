import { useState, useCallback, useMemo, createContext, useContext } from 'react';
import { useUser } from './UserProvider';

export class ProviderBuilder {
    public static buildProvider<T extends IDataBase>(initData: T, fetcher: (token: string) => Promise<T>): BaseProviderType<T> {
        const [dataState, setDataState] = useState<T>(initData);

        const { user } = useUser();

        const fetchData = useCallback(async (): Promise<void> => {
            if (!user) return;
            const res = await fetcher(user.token);
            res.wasFetched = true;
            setDataState(res);
        }, [user]);

        const clearData = useCallback(() => {
            setDataState(initData);
        }, []);

        const setData = useCallback((data: T) => {
            setDataState(data);
        }, []);

        const dataValue = useMemo(
            () => ({
                data: dataState,
                setData,
                clearData,
                fetchData,
            }),
            [dataState, setData, clearData, fetchData],
        );

        return dataValue;
    }

    public static buildContext<T>(): React.Context<BaseProviderType<T> | undefined> {
        return createContext<BaseProviderType<T> | undefined>(undefined);
    }

    public static buildUseHook<T>(context: React.Context<BaseProviderType<T> | undefined>, name: string): () => BaseProviderType<T> {
        return () => {
            const contextValue = useContext(context);
            if (contextValue === undefined) {
                console.error(`${name} must be used within a Provider`);
                throw new Error(`${name} must be used within a Provider`);
            }
            return contextValue;
        };
    }
}
