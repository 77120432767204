import { PropsWithChildren } from 'react';
import { fetchCustomerData } from '../api/customerData';
import { ProviderBuilder } from './ProviderBuilder';

const initialData: ICustomerData = {
    age: {
        averageFemale: 0,
        averageMale: 0,
    },
    gender: {
        percentFemale: 0,
        percentMale: 0,
    },
    averagePersons: {
        averageThisWeek: 0,
        averageLastWeek: 0,
        rankIndicator: 'equal',
    },
    averageStay: {
        averageThisWeek: 0,
        averageLastWeek: 0,
        rankIndicator: 'equal',
    },
    googleRating: {
        differenceToPreLastWeek: '0',
        differenceToPreLastWeekIndicator: 'equal',
        lastWeek: 0,
    },
    npsByOutlet: [],
    npsTotal: [],
    trend: [],
    crmProductivity: {
        data: [],
        labels: [],
    },
    npsBookings: {
        data: [],
        labels: [],
    },
    wasFetched: false,
};

const CustomerDataContext = ProviderBuilder.buildContext<ICustomerData>();

const CustomerDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const customerDataValue = ProviderBuilder.buildProvider(initialData, fetchCustomerData);

    return <CustomerDataContext.Provider value={customerDataValue}>{children}</CustomerDataContext.Provider>;
};

const useCustomerData = ProviderBuilder.buildUseHook(CustomerDataContext, 'useCustomerData');

export default CustomerDataProvider;
export { useCustomerData, CustomerDataContext };
