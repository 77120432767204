import { FunctionComponent } from 'react';
import RankIndicator from '../../RankIndicator';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const GoogleRatingCard: FunctionComponent<GraphAnalyticsCardProps<IGoogleRating>> = ({ data, fetchData }) => {
    return (
        <AnalyticsCard
            title="Google-Rezensionen"
            subtitle="Score"
            tooltip="Durchschnittliche google Bewertung im Vergleich zur Vorwoche"
            editableConfigIdentifier="google_rating"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <div className="grid grid-cols-1a1 h-full">
                <div className="text-right self-end">
                    <p className="text-5.5xl font-bold">{data.lastWeek}</p>
                    <p className="text-2xl mt-6">Letzte Woche</p>
                </div>
                <RankIndicator direction={data.differenceToPreLastWeekIndicator} />
                <div className="text-left self-end">
                    <p className="mt-7 text-2xl">{data.differenceToPreLastWeek}</p>
                    <p className="mt-7 text-2xl">Vorletzte Woche</p>
                </div>
            </div>
        </AnalyticsCard>
    );
};

export default GoogleRatingCard;
