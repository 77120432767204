import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import fetchOutletComparisonData from '../../api/kpiQuality/outletComparison';

const initialData: IOutletComparisonData = {
    productivity: { labels: [], data: {} },
    resignationInProbationary: { labels: [], data: {} },
    outletSales: {
        labels: [],
        data: [],
    },
    outletFeedback: {
        labels: [],
        data: {
            cleanliness: [],
            friendliness: [],
            punctuality: [],
        },
        counts: {
            cleanliness: [],
            friendliness: [],
            punctuality: [],
        },
    },
    wasFetched: false,
};

const OutletComparisonDataContext = ProviderBuilder.buildContext<IOutletComparisonData>();

const OutletComparisonProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiQualityDataValue = ProviderBuilder.buildProvider<IOutletComparisonData>(initialData, fetchOutletComparisonData);

    return <OutletComparisonDataContext.Provider value={kpiQualityDataValue}>{children}</OutletComparisonDataContext.Provider>;
};

const useOutletComparisonData = ProviderBuilder.buildUseHook(OutletComparisonDataContext, 'useOutletComparisonData');

export default OutletComparisonProvider;
export { useOutletComparisonData, OutletComparisonDataContext };
