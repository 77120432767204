import { ChartData, ChartOptions, Point } from 'chart.js/dist/types/index';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const WorkloadOutletCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels }) => {
    // Auslastung Filiale - Line Chart
    const dataWorkloadOutlet: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                borderColor: 'transparent',
                pointBackgroundColor: '#558ba0',
                pointRadius: 14,
                pointHoverRadius: 16,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'white',
                },
            },
        ],
    };

    const workloadOutletMin = Math.min(...data);
    const workloadOutletMax = Math.max(...data);

    const optionsWorkloadOutlet: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: workloadOutletMax,
                        yMax: workloadOutletMax,
                        borderColor: '#90AF76',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.55,
                        yValue: workloadOutletMax - 1,
                        content: [`max. ${workloadOutletMax}%`],
                        color: '#90AF76',
                    },
                    line2: {
                        type: 'line',
                        yMin: workloadOutletMin,
                        yMax: workloadOutletMin,
                        borderColor: '#eb6253',
                        borderDash: [10, 10],
                    },
                    label2: {
                        type: 'label',
                        xValue: 0.55,
                        yValue: workloadOutletMin + 1,
                        content: [`min. ${workloadOutletMin}%`],
                        color: '#eb6253',
                    },
                },
            },
        },
        scales: {
            y: {
                display: false,
            },
            x: {
                ticks: {
                    font: {
                        weight: 'bold',
                    },
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Auslastung"
            subtitle="pro Filiale | in %"
            live
            tooltip="Prozentualer Anteil der tatsächlich stattgefundenen Wellnessstunden ohne Reinigungsslots für die letzte Kalenderwoche pro Filiale"
        >
            <Line
                data={dataWorkloadOutlet}
                options={optionsWorkloadOutlet}
            />
        </AnalyticsCard>
    );
};

export default WorkloadOutletCard;
