import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const DefectQuota1Card: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels }) => {
    // Defektquote 1 - Line chart
    const dataDefectQuote1: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                borderColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsDefectQuote1: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Defektquote 1"
            subtitle="starke Defekte | in %"
            tooltip="in der KW neu angelegte Starke defekte (Sauna, massage)"
            live
        >
            <Line
                data={dataDefectQuote1}
                options={optionsDefectQuote1}
            />
        </AnalyticsCard>
    );
};
export default DefectQuota1Card;
