import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OvertimeOutletsCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, fetchData, labels }) => {
    // Überstunden pro Filiale - Bar chart
    const dataOvertimeOutletsValues = data;
    const valuesCountOvertimeOutlets = dataOvertimeOutletsValues.length;
    const dataOvertimeOutletsAverage = valuesCountOvertimeOutlets
        ? dataOvertimeOutletsValues.reduce((a, b) => a + b) / valuesCountOvertimeOutlets
        : 0;

    const dataOvertimeOutlets: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Previous',
                data: dataOvertimeOutletsValues,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsOvertimeOutlets: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: dataOvertimeOutletsAverage,
                        yMax: dataOvertimeOutletsAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: dataOvertimeOutletsAverage + 40,
                        content: [`Durchschnitt: ${dataOvertimeOutletsAverage.toFixed(1)}`],
                        color: 'gray',
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 25,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Überstunden"
            subtitle="pro Filiale | in Std."
            editableConfigIdentifier="overtime_per_outlet"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataOvertimeOutlets}
                options={optionsOvertimeOutlets}
            />
        </AnalyticsCard>
    );
};
export default OvertimeOutletsCard;
