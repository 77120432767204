/* eslint-disable max-len */
import { FunctionComponent } from 'react';
import MywLogo from '../icons/myw_logo.svg';

const LoadSpinner: FunctionComponent = () => (
    <div
        className="loading-wrapper flex flex-col absolute w-[calc(100%-2rem)] md:w-[calc(100%-5rem)] xl:w-[calc(100%-10rem)] top-[calc(50%-6rem)] items-center justify-center px-4 md:px-10 xl:px-20"
        key="order_page_loading_orders"
    >
        <img
            className="animate-spin-horizontal w-48"
            src={MywLogo}
            alt=""
        />
        <p className="text-2xl mt-6">Laden ...</p>
    </div>
);

export default LoadSpinner;
