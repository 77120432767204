import { FunctionComponent } from 'react';

type Startype = {
    number: number;
};

const StarIcon: FunctionComponent<Startype> = ({ number }) => (
    <svg
        className="ml-1 w-[30px] h-[30px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.435 31.799"
    >
        <defs>
            <linearGradient id={`grad_${number}`}>
                <stop
                    offset={`${number}%`}
                    stopColor="currentColor"
                />
                <stop
                    offset="0%"
                    stopColor="white"
                    stopOpacity="1"
                />
            </linearGradient>
        </defs>
        <path
            stroke="#000"
            strokeMiterlimit="10"
            strokeWidth="2.5"
            fill={`url(#grad_${number})`}
            d="m16.717 3.389 4.17 8.449 9.324 1.355-6.747 6.577 1.592 9.286-8.34-4.385-8.34 4.385 1.592-9.286-6.747-6.577 9.324-1.355Z"
            data-name="Pfad 806"
        />
    </svg>
);

const getStarArray = (number: number): JSX.Element[] => {
    const StarsArr = [];
    for (let i = 0; i < 5; i += 1) {
        let fillage = 100;
        if (number - i < 1 && number - i > 0) {
            fillage = (number - i) * 100;
        } else if (number - i <= 0) {
            fillage = 0;
        }
        StarsArr.push(
            <StarIcon
                key={Math.random()}
                number={Math.floor(fillage)}
            />,
        );
    }
    return StarsArr;
};

const StarRating: FunctionComponent<Startype> = ({ number }) => (
    <div className="flex flex-col justify-end">
        <div className="flex justify-end">{getStarArray(number).map((el) => el)}</div>
        <div className="text-right mt-5">
            {number ? (
                <>
                    <span className="text-4xl font-bold mr-3">{number}</span>
                    <span className="text-4xl font-bold uppercase tracking-wide">Sterne</span>
                </>
            ) : (
                <span className="mr-3">&mdash;</span>
            )}
        </div>
    </div>
);

export default StarRating;
