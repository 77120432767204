import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartData, ChartOptions, Point } from 'chart.js';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const NPSTrendCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        trend: {
            score: number;
            label: string;
        }[];
    }>
> = ({ data }) => {
    const dataTrend: ChartData<'line', (number | Point | null)[], unknown> = {
        labels: data.trend.map((item) => item.label),
        datasets: [
            {
                data: data.trend.map((item) => Number(item.score.toFixed(0))),
                borderColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsTrend: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="NPS Trend"
            subtitle="pro Monat"
            tooltip="NPS = Promotoren (in % aller Befragten) - Detraktoren (in % aller Befragten) im Monatsverlauf"
            live
        >
            <Line
                data={dataTrend}
                options={optionsTrend}
            />
        </AnalyticsCard>
    );
};

export default NPSTrendCard;
