import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

// TODO: Refactor this type to be an object with the correct keys
const MarketingCostByChannelCard: FunctionComponent<GraphAnalyticsCardProps<IMultiLineGraphData[]>> = ({ data, fetchData, labels }) => {
    // Marketing-Kosten - Bar Chart
    const dataCosts: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'SEA',
                data: data.find((item) => item.label === 'sea')?.data || [],
                backgroundColor: ['#558ba0'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Display',
                data: data.find((item) => item.label === 'display')?.data || [],
                backgroundColor: ['#edcd77'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'YouTube',
                data: data.find((item) => item.label === 'youtube')?.data || [],
                backgroundColor: ['#eb6253'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Meta Ads',
                data: data.find((item) => item.label === 'meta_ads')?.data || [],
                backgroundColor: ['#738B7A'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Pinterest Ads',
                data: data.find((item) => item.label === 'pinterest_ads')?.data || [],
                backgroundColor: ['#90AF76'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'TikTok Ads',
                data: data.find((item) => item.label === 'tiktok_ads')?.data || [],
                backgroundColor: ['#2d435b'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsCosts: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                align: 'center',
            },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
        },
        scales: {
            y: {
                display: false,
                stacked: true,
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Media-Kosten"
            subtitle="Wert absolut in €"
            tooltip="Aufteilung der Kosten in Kanal"
            editableConfigIdentifier="marketing_cost_by_channel"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataCosts}
                options={optionsCosts}
            />
        </AnalyticsCard>
    );
};
export default MarketingCostByChannelCard;
