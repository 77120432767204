import axios from 'axios';
import { FormEvent, useState } from 'react';
import { AdminUserResponse, LoginResponse } from '@mywellness-gmbh/backend-types';
import { baseUrl, sendGetRequest } from '../../api';
import { useUser } from '../../context/UserProvider';

const LoginModal: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const { setUser } = useUser();

    const onSubmitHandler = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        // actually log in here
        try {
            setErrorMessage('');
            const res = await axios.post<LoginResponse>(`${baseUrl}/auth/login`, {
                email,
                password,
            });
            if (res.data.meta.success) {
                const { token } = res.data.result;
                const user: IUser = {
                    email,
                    token,
                };
                const adminUserData = await sendGetRequest<AdminUserResponse>(user.token, '/auth/adminUser');
                if (adminUserData.meta.success) {
                    const permissions = await sendGetRequest<IBaseApiResponse<IAdminUserPermissions[]>>(user.token, '/auth/permissions');
                    if (permissions.meta.success) {
                        if (permissions.result.find((permission) => permission.rule === 'VIEW_COCKPIT')) {
                            setUser(user);
                            return;
                        }
                    }
                    setErrorMessage('Du hast keine Berechtigung für das Dashboard.');
                    return;
                }
                setErrorMessage('Es ist ein Fehler aufgetreten, bitte versuche es später erneut. (FFF)');
            } else {
                setErrorMessage(res.data.meta.message);
                console.error(res.data.meta.message);
            }
        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                setErrorMessage('Es ist ein Fehler aufgetreten, bitte versuche es später erneut. (AAA)');
            }
        }
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, source: 'email' | 'password'): void => {
        if (source === 'email') {
            setEmail(e.target.value);
        } else if (source === 'password') {
            setPassword(e.target.value);
        }
    };

    return (
        <div className="fixed flex justify-center items-center w-screen h-screen bg-dark-chocolate/70 backdrop-blur-sm z-20">
            <form
                className="flex  flex-col items-center justify-center bg-white p-8 rounded shadow-sm"
                onSubmit={onSubmitHandler}
            >
                <h3 className="text-3xl font-bold mb-8">Login</h3>
                <p className="text-danger">{errorMessage}</p>
                <label htmlFor="email">
                    Username
                    <input
                        className="mb-2 ml-2 p-2 bg-boho"
                        type="email"
                        id="email"
                        onChange={(e): void => onChangeHandler(e, 'email')}
                        value={email}
                    />
                </label>
                <label htmlFor="password">
                    Password
                    <input
                        className="mb-2 ml-2 p-2 bg-boho"
                        type="password"
                        id="password"
                        onChange={(e): void => onChangeHandler(e, 'password')}
                        value={password}
                    />
                </label>
                <button
                    className="mt-7 rounded bg-green-apple px-8 py-4 text-white uppercase"
                    type="submit"
                >
                    Login
                </button>
            </form>
        </div>
    );
};

export default LoginModal;
