import {
    CustomerDiscountShareByOutletResponse,
    CustomerDiscountTotalByCalendarWeekResponse,
    RevenueOnSiteConsumptionByCalendarWeekResponse,
    RevenueOnSiteConsumptionByOutletResponse,
    Timeslot,
} from '@mywellness-gmbh/backend-types';
import { isBefore, parseISO, startOfMonth, subMonths } from 'date-fns';
import { getAbsoluteDataMulti, getSingleLineGraph, loadAnalyticsData } from '../storedAnalytics';
import { sendGetRequest } from '../index';

const getAuditsForLastMonths = async (token: string, months: number): Promise<IAbsoluteDataMulti> => {
    const audits = await loadAnalyticsData(token, 'audit_grade', Timeslot.lastTwelveMotnhsToThisMonth);

    const labels = audits.result.find((singleLineGraphData) => singleLineGraphData.values.length)?.values?.map((value) => value.identifier);

    if (!labels?.length) {
        return {
            labels: [],
            data: [],
        };
    }

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - months, 1);

    const auditsByOutlet = audits.result
        .flatMap((singleLineGraphData) => singleLineGraphData.values)
        .filter((value) => {
            const valueDate = new Date(value.date);
            return valueDate >= startDate && valueDate <= currentDate;
        })
        .reduce(
            (acc, curr) => {
                if (!acc[curr.identifier]) acc[curr.identifier] = [];
                acc[curr.identifier].push(curr.value);
                return acc;
            },
            {} as Record<string, number[]>,
        );

    const data = labels.map((label) => {
        const resultsForOutlet = auditsByOutlet[label] || [];
        return resultsForOutlet.reduce((acc, curr) => acc + curr, 0);
    });

    return {
        labels,
        data,
    };
};

const getAuditsByMonth = async (
    token: string,
): Promise<{
    labels: string[];
    data: { currentMonth: number[]; currentMinusOne: number[]; currentMinusTwo: number[]; currentMinusThree: number[] };
}> => {
    const audits = await loadAnalyticsData(token, 'audit_grade', Timeslot.lastTwelveMonths);

    const fourMonthResponse = audits.result.filter((audit) => {
        const auditDate = parseISO(`${audit.interval.split('/').reverse().join('-')}-01`);
        const fourMonthsAgo = startOfMonth(subMonths(new Date(), 5));
        const startOfCurrentMonth = startOfMonth(new Date());
        return isBefore(fourMonthsAgo, auditDate) && isBefore(auditDate, startOfCurrentMonth);
    });

    const months = fourMonthResponse.map((audit) => audit.interval);

    const labels = audits.result.find((singleLineGraphData) => singleLineGraphData.values.length)?.values?.map((value) => value.identifier);

    if (!labels?.length || fourMonthResponse.length < 4) {
        return {
            labels: [],
            data: {
                currentMonth: [],
                currentMinusOne: [],
                currentMinusTwo: [],
                currentMinusThree: [],
            },
        };
    }

    const data = months.map((month) => {
        const monthData = fourMonthResponse.find((audit) => audit.interval === month);
        const values = labels.map((label) => {
            const labelValue = monthData?.values.find((value) => value.identifier === label)?.value;
            return labelValue || 0;
        });
        return values;
    });

    return {
        labels,
        data: {
            currentMonth: data[3],
            currentMinusOne: data[2],
            currentMinusTwo: data[1],
            currentMinusThree: data[0],
        },
    };
};

const fetchOperationsPage = async (token: string): Promise<IOperationsData> => {
    const overtimeTotalRequest = getSingleLineGraph(token, 'overtime_total');
    const overtimeByOutletRequest = getAbsoluteDataMulti(token, 'overtime_per_outlet', Timeslot.lastMonth);
    const auditLastGradeRequest = getAuditsForLastMonths(token, 1);
    const auditLastFourMonthRequest = getAuditsByMonth(token);
    const hourlyOutputByOutletRequest = getAbsoluteDataMulti(token, 'hourly_output_per_outlet', Timeslot.lastMonth);
    const sicknessQuotaTotalRequest = getSingleLineGraph(token, 'sickness_quota_total');
    const sicknessQuotaByOutletRequest = getAbsoluteDataMulti(token, 'sickness_quota_per_outlet', Timeslot.lastMonth);
    const customerDiscountShareRequest = sendGetRequest<CustomerDiscountShareByOutletResponse>(
        token,
        `/statistics/customerDiscount/shareByOutlet?timeslot=${Timeslot.twelveWeeks}`,
    );
    const customerDiscountTotalRequest = sendGetRequest<CustomerDiscountTotalByCalendarWeekResponse>(
        token,
        `/statistics/customerDiscount/totalByCalendarWeek?timeslot=${Timeslot.twelveWeeks}`,
    );
    const onSiteConsumptionByOutletRequest = sendGetRequest<RevenueOnSiteConsumptionByOutletResponse>(
        token,
        `/statistics/revenue/onSiteConsumption/byOutlet?timeslot=${Timeslot.twelveWeeks}`,
    );
    const onSiteConsumptionTotalRequest = sendGetRequest<RevenueOnSiteConsumptionByCalendarWeekResponse>(
        token,
        `/statistics/revenue/onSiteConsumption/byCalendarWeek?timeslot=${Timeslot.twelveWeeks}`,
    );

    const [
        overtimeTotal,
        overtimeByOutlet,
        auditLastGrade,
        auditLastFourMonth,
        hourlyOutputByOutlet,
        sicknessQuotaTotal,
        sicknessQuotaByOutlet,
        customerDiscountShare,
        customerDiscountTotal,
        onSiteConsumptionByOutlet,
        onSiteConsumptionTotal,
    ] = await Promise.all([
        overtimeTotalRequest,
        overtimeByOutletRequest,
        auditLastGradeRequest,
        auditLastFourMonthRequest,
        hourlyOutputByOutletRequest,
        sicknessQuotaTotalRequest,
        sicknessQuotaByOutletRequest,
        customerDiscountShareRequest,
        customerDiscountTotalRequest,
        onSiteConsumptionByOutletRequest,
        onSiteConsumptionTotalRequest,
    ]);

    return {
        overtimeTotal,
        overtimeByOutlet,
        auditLastGrade,
        auditLastFourMonth,
        hourlyOutputByOutlet,
        sicknessQuotaTotal,
        sicknessQuotaByOutlet,
        customerDiscountShare: customerDiscountShare.result.reduce(
            (acc, curr) => {
                acc.labels.push(curr.displayName);
                acc.data.push(curr.discount);
                return acc;
            },
            {
                labels: new Array<string>(),
                data: new Array<number>(),
            },
        ),
        customerDiscountTotal: customerDiscountTotal.result.reduce(
            (acc, curr) => {
                acc.labels.push(`KW ${curr.calendarWeek}`);
                acc.data.push(curr.total);
                return acc;
            },
            {
                labels: new Array<string>(),
                data: new Array<number>(),
            },
        ),
        onSiteConsumptionByOutlet: onSiteConsumptionByOutlet.result.reduce(
            (acc, curr) => {
                acc.labels.push(curr.displayName);
                acc.data.push(curr.revenue);
                return acc;
            },
            {
                labels: new Array<string>(),
                data: new Array<number>(),
            },
        ),
        onSiteConsumptionTotal: onSiteConsumptionTotal.result.reduce(
            (acc, curr) => {
                acc.labels.push(`KW ${curr.calendarWeek}`);
                acc.data.push(Math.floor(curr.revenue));
                return acc;
            },
            {
                labels: new Array<string>(),
                data: new Array<number>(),
            },
        ),
        wasFetched: true,
    };
};

export default fetchOperationsPage;
