import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const LastAuditCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, fetchData, labels }) => {
    // Note letzter Audit - Bar chart

    const valuesCountLastAudit = data.length;
    const dataLastAuditAverage = valuesCountLastAudit ? data.reduce((a, b) => a + b) / valuesCountLastAudit : 0;

    const dataLastAudit: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Previous',
                data,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsLastAudit: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        yMin: dataLastAuditAverage,
                        yMax: dataLastAuditAverage,
                        borderColor: 'gray',
                        borderDash: [10, 10],
                    },
                    label1: {
                        type: 'label',
                        xValue: 0.4,
                        yValue: dataLastAuditAverage + 0.6,
                        content: [`Durchschnitt: ${dataLastAuditAverage.toFixed(1)}`],
                        color: 'gray',
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Audit"
            subtitle="letzter Monat jeder Standort"
            editableConfigIdentifier="audit_grade"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataLastAudit}
                options={optionsLastAudit}
            />
        </AnalyticsCard>
    );
};
export default LastAuditCard;
