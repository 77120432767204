import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { LastOutletScore } from '@mywellness-gmbh/backend-types';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { getColorFromRating } from '../../../util';

function allAverages(data: LastOutletScore[][]): number {
    const filtered = data.flat().filter((outlet) => outlet.rating !== null);
    return filtered.reduce((acc, s) => acc + (s.rating || 0), 0) / filtered.length;
}
interface LastThreeScoresPerOutletProps extends GraphAnalyticsCardProps<LastOutletScore[][]> {
    type?: 'customer' | 'service';
}

const LastThreeScoresPerOutlet: React.FC<LastThreeScoresPerOutletProps> = ({ data, labels, type }) => {
    const outletScoreData: ChartData<'bar'> = {
        labels,
        datasets: data.map((iteration, idx) => ({
            label: ['vorvorletztes Audit', 'vorletztes Audit', 'letztes Audit'][idx],
            data: iteration.map((outlet) => outlet.rating),
            backgroundColor: iteration.map((outlet) => getColorFromRating(outlet.rating)),
            pointStyle: 'dash',
            datalabels: {
                font: {
                    size: 16,
                },
                color: 'black',
            },
        })),
    };
    const outletScoreOptions: ChartOptions<'bar'> = {
        onClick(_, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const { auditId } = data[element.datasetIndex][element.index];
                if (auditId) {
                    window.open(`http://intraapp.mywellness.de/audit/resultreport/${auditId}`, '_blank');
                }
            }
        },
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        yMin: allAverages(data),
                        yMax: allAverages(data),
                        borderDash: [10, 10],
                    },
                ],
            },
        },
        scales: {
            x: {
                stacked: false,
            },
            y: {
                max: 100,
                stacked: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title={type === 'service' ? 'Service Audit Vergleich' : 'Customer-Experience Audit Vergleich'}
            subtitle="letzten 3 Audits pro Standort"
            tooltip="Die letzten 3 Audits pro Standort im Vergleich"
        >
            <Bar
                data={outletScoreData}
                options={outletScoreOptions}
            />
        </AnalyticsCard>
    );
};

export default LastThreeScoresPerOutlet;
