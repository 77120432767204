import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import { LastOutletScore } from '@mywellness-gmbh/backend-types';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import { getColorFromRating } from '../../../util';

const LastScoresPerOutlet: React.FC<GraphAnalyticsCardProps<LastOutletScore[]>> = ({ data, labels }) => {
    const outletScoreData: ChartData<'bar'> = {
        labels,
        datasets: [
            {
                label: 'letzter Audit',
                data: data.map((outlet) => outlet.rating),
                backgroundColor: data.map((outlet) => getColorFromRating(outlet.rating)),
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };
    const outletScoreOptions: ChartOptions<'bar'> = {
        onClick(_, elements) {
            if (elements.length > 0) {
                const element = elements[0];
                const { auditId } = data[element.index];
                if (auditId) {
                    window.open(`http://intraapp.mywellness.de/audit/resultreport/${auditId}`, '_blank');
                }
            }
        },
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: data.map(() => {
                    const filtered = data.filter((outlet) => outlet.rating !== null);
                    const avrg = filtered.reduce((acc, s) => acc + (s.rating || 0), 0) / filtered.length;
                    return {
                        type: 'line',
                        yMin: avrg,
                        yMax: avrg,
                        borderDash: [10, 10],
                    };
                }),
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                max: 100,
                stacked: true,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Letzte Bewertungen"
            subtitle="pro Outlet"
        >
            <Bar
                data={outletScoreData}
                options={outletScoreOptions}
            />
        </AnalyticsCard>
    );
};

export default LastScoresPerOutlet;
