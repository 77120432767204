import axios from 'axios';

export const baseUrl = process.env.REACT_APP_API;

/**
 * Send a preconfigured request to the backend
 *
 * @param token The JWT token
 * @param url The backend url to send the request to
 * @returns A promise that resolves to the data
 */
export const sendGetRequest = async <T>(token: string, url: string): Promise<T> => {
    const res = await axios.get<T>(`${baseUrl}${url}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });

    return res.data;
};

/**
 * Send a preconfigured request to the backend
 *
 * @param token The JWT token
 * @param url The backend url to send the request to
 * @param body The body of the request
 * @returns A promise that resolves to the data
 */
export const sendPostRequest = async <T>(token: string, url: string, body: unknown): Promise<T> => {
    const res = await axios.post<T>(`${baseUrl}${url}`, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    return res.data;
};
