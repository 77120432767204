import React, { useEffect, useRef, useState } from 'react';
import style from '../styles/components/pie_chart.module.scss';

// TODO: Percent should be a number
interface Title {
    title: string;
    percent: string;
}

const Chart: React.FunctionComponent<Title> = ({ title, percent }) => {
    const [counter, setCounter] = useState(0);
    const count = useRef(counter);
    count.current = counter;

    function countUp(): void {
        setCounter((currentCount) => currentCount + 1);
    }

    function countDown(): void {
        if (count.current === 0) {
            setCounter(0);
        } else if (count.current > 0) {
            setCounter((currentCount) => currentCount - 1);
        }
    }

    useEffect(() => {
        const countUpInterval = setInterval(() => {
            if (count.current < parseInt(percent, 10)) {
                countUp();
            } else if (count.current > parseInt(percent, 10)) {
                countDown();
            } else {
                clearInterval(countUpInterval);
            }
        }, 50);
        return () => clearInterval(countUpInterval);
    }, [percent]);

    return (
        <div
            className={style.pie_chart}
            style={{ ['--percent' as string]: counter }}
        >
            <div className={`${style.piechart_wrapper} ${title === 'Vorwoche' || title === 'Vorjahr' ? 'saturate-0' : ''}`}>
                <div className={style.pie} />
                <span className={style.percent}>{counter}</span>
            </div>
            <p className={style.title}>{title}</p>
        </div>
    );
};

export default Chart;
