/* eslint-disable max-len */
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import EditValuesModal from '../modal/EditValuesModal';
import ChartDetailModal from '../modal/ChartDetailModal';
import infoIcon from '../../icons/icon__info.svg';
import penIcon from '../../icons/icon__pen.svg';
import magnifyGlassIcon from '../../icons/icon__magnify-glass.svg';

interface AnalyticsCardProps {
    title: string;
    icon?: string;
    editableConfigIdentifier?: string | { identifier: string; humanReadable: string }[];
    children: ReactNode;
    subtitle?: string;
    live?: boolean;
    tooltip?: string;
    onSaveEditableConfig?: () => void;
}

export interface GraphAnalyticsCardProps<T = number[]> {
    data: T;
    fetchData?: () => void;
    labels?: string[];
    counts?: number[];
    title?: string;
    subtitle?: string;
}

const AnalyticsCard: FunctionComponent<AnalyticsCardProps> = ({
    title,
    icon,
    children,
    subtitle,
    live = false,
    tooltip,
    editableConfigIdentifier = '',
    onSaveEditableConfig,
}) => {
    function isEditable(): boolean {
        if (Array.isArray(editableConfigIdentifier)) {
            return editableConfigIdentifier.length > 0;
        }

        return !!editableConfigIdentifier;
    }
    const [currentWidth, setWidth] = useState(0);
    const [currentOpacityHeading, setOpacityHeading] = useState(0);
    const [currentScoreOpacity, setScoreOpacity] = useState(0);
    const [editValuesModalOpen, setEditValuesModalOpen] = useState(false);
    const [chartDetailModalOpen, setChartDetailModalOpen] = useState(false);
    const editable = isEditable();

    function addWidth(): void {
        setWidth(1);
    }
    function setOpacity(): void {
        setOpacityHeading(1);
    }
    function setScore(): void {
        setScoreOpacity(1);
    }

    useEffect(() => {
        addWidth();
        setTimeout(setOpacity, 700);
        setTimeout(setScore, 1400);
    }, []);

    const openEditValuesModal = (): void => {
        setEditValuesModalOpen(true);
    };
    const closeEditValuesModal = (): void => {
        if (onSaveEditableConfig) onSaveEditableConfig();
        setEditValuesModalOpen(false);
    };
    const openChartDetailModal = (): void => {
        setChartDetailModalOpen(true);
    };
    const closeChartDetailModal = (): void => {
        setChartDetailModalOpen(false);
    };

    return (
        <>
            <section
                className="card"
                style={{ transform: `scaleX(${currentWidth})` }}
            >
                <aside
                    className="flex items-center gap-x-6 justify-end max-sm:mb-4 duration-500 transition-opacity"
                    style={{ opacity: currentOpacityHeading }}
                >
                    {editable && (
                        <button
                            type="button"
                            className="duration-500 transition-opacity"
                            onClick={openEditValuesModal}
                        >
                            <img
                                src={penIcon}
                                alt="Pen Icon"
                                className="h-6"
                            />
                        </button>
                    )}

                    <button
                        type="button"
                        className="w-7 h-7"
                        onClick={openChartDetailModal}
                    >
                        <img
                            src={magnifyGlassIcon}
                            alt="magnify glass icon"
                        />
                    </button>

                    <div className={`relative group w-8 h-8 p-0.5 rounded-full  ${live || editable ? 'bg-green-apple' : 'bg-danger'}`}>
                        {(live || editable) && (
                            <div className="absolute top-5 right-6 invisible group-hover:visible w-48 px-2 py-1 rounded z-10 bg-white shadow-sm opacity-0 group-hover:opacity-100">
                                <p>{tooltip || `${title} ${subtitle || ''}`}</p>
                            </div>
                        )}
                        <img
                            src={infoIcon}
                            alt="info icon"
                        />
                    </div>
                </aside>

                <header
                    className="card-header"
                    style={{ opacity: currentOpacityHeading }}
                >
                    <div>
                        <h2 className="inline-block mb-1 font-bold text-4xl uppercase tracking-wider break-word">
                            <img
                                className="inline-block h-8 -translate-y-1"
                                src={icon}
                                alt=""
                            />
                            {title}
                        </h2>
                    </div>
                    {subtitle && <p className="text-center text-2xl">{subtitle}</p>}
                </header>

                <div
                    className="duration-500 transition-opacity overflow-y-auto h-60"
                    style={{ opacity: currentScoreOpacity }}
                >
                    {children}
                </div>
            </section>

            {editValuesModalOpen && (
                <EditValuesModal
                    closeModal={closeEditValuesModal}
                    configIdentifiers={
                        Array.isArray(editableConfigIdentifier) ? editableConfigIdentifier : [{ identifier: editableConfigIdentifier }]
                    }
                    name={title}
                />
            )}

            {chartDetailModalOpen && (
                <ChartDetailModal
                    closeModal={closeChartDetailModal}
                    live={live}
                    title={title}
                    subtitle={subtitle}
                    editable={editable}
                    tooltip={tooltip}
                    icon={icon}
                >
                    {children}
                </ChartDetailModal>
            )}
        </>
    );
};

export default AnalyticsCard;
