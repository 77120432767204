import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const FutureQuotientCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        futureQuotient: [
            IFutureQuotientByOutletAndWeek[],
            IFutureQuotientByOutletAndWeek[],
            IFutureQuotientByOutletAndWeek[],
            IFutureQuotientByOutletAndWeek[],
        ];
    }> & { weekOffset: 1 | 2 | 3 | 4; tooltip: string }
> = ({ data, tooltip, weekOffset }) => {
    // Zukunftsquotient - Line Chart
    const getDataFutureQuotientData = (): ChartData<'line', (number | Point | null)[], unknown> => {
        return {
            labels: data.futureQuotient[weekOffset - 1].map((item) => item.outletName),
            datasets: [
                {
                    data: data.futureQuotient[weekOffset - 1].map((item) => item.quotient),
                    borderColor: 'transparent',
                    pointBackgroundColor: '#558ba0',
                    pointRadius: 16,
                    pointHoverRadius: 18,
                    datalabels: {
                        font: {
                            weight: 'bold',
                            size: 18,
                        },
                        color: 'white',
                    },
                },
            ],
        };
    };

    const getOptionsFutureQuotient = (): ChartOptions<'line'> => {
        let threshold = 0;

        switch (weekOffset) {
            case 1:
                threshold = 63;
                break;
            case 2:
                threshold = 45;
                break;
            case 3:
                threshold = 30;
                break;
            case 4:
                threshold = 20;
                break;
            default:
                throw new Error('Invalid week offset');
        }

        return {
            plugins: {
                tooltip: { enabled: false },
                legend: {
                    display: false,
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: threshold,
                            yMax: threshold,
                            borderColor: '#eb6253',
                            borderDash: [10, 10],
                        },
                        label1: {
                            type: 'label',
                            xValue: 0.5,
                            yValue: threshold - 8,
                            content: [`${threshold} %`],
                            color: '#eb6253',
                        },
                    },
                },
            },
            scales: {
                y: {
                    display: false,
                    beginAtZero: true,
                },
                x: {
                    ticks: {
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            },
            maintainAspectRatio: false,
        };
    };

    return (
        <AnalyticsCard
            title={`Zukunftsquotient KW + ${weekOffset}`}
            subtitle="pro Filiale | in %"
            live
            tooltip={tooltip}
        >
            <Line
                data={getDataFutureQuotientData()}
                options={getOptionsFutureQuotient()}
            />
        </AnalyticsCard>
    );
};

export default FutureQuotientCard;
