import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const EngagementRateCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        currentCw: number[];
        currentMinusOne: number[];
        currentMinusTwo: number[];
    }>
> = (props) => {
    const { data, fetchData, labels } = props;

    // // Engagement Rate - Bar chart horizontal
    const dataSEngagementRate: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: '-2 KW',
                data: data.currentMinusTwo,
                backgroundColor: ['rgba(230, 118, 90, 0.3)', 'rgba(85, 139, 160, 0.3)', 'rgba(237, 205, 119, 0.3)'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: '-1 KW',
                data: data.currentMinusOne,
                backgroundColor: ['rgba(230, 118, 90, 0.6)', 'rgba(85, 139, 160, 0.6)', 'rgba(237, 205, 119, 0.6)'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Aktuelle KW',
                data: data.currentCw,
                backgroundColor: ['#e6765a', '#558ba0', '#edcd77'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsEngagementRate: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        weight: 'bold',
                    },
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Engagement Rate"
            subtitle="in %"
            tooltip="Die Engagement-Rate gibt an, wie viele Menschen, die einen Social-Media-Beitrag sehen, auch mit Likes, Kommentaren oder Shares reagieren."
            editableConfigIdentifier="engagement_rate"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Bar
                data={dataSEngagementRate}
                options={optionsEngagementRate}
            />
        </AnalyticsCard>
    );
};

export default EngagementRateCard;
