import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const DefectQuotaByStatusCard: FunctionComponent<
    GraphAnalyticsCardProps<{
        closed: number[];
        draft: number[];
        makeover: number[];
        open: number[];
        pending: number[];
        renovation: number[];
        resolved: number[];
    }>
> = ({ data, labels }) => {
    // Defektquote bei Status - Line Chart
    const dataDefectQuoteByStatus: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Offene Tickets',
                data: data.open,
                borderColor: '#edcd77',
                backgroundColor: '#edcd77',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'In Bearbeitung',
                data: data.pending,
                borderColor: '#558ba0',
                backgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Abgeschlossen',
                data: data.closed,
                borderColor: '#90AF76',
                backgroundColor: '#90AF76',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Entwurf',
                data: data.draft,
                borderColor: '#a1c1ce',
                backgroundColor: '#a1c1ce',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Umbau',
                data: data.renovation,
                borderColor: '#bac6bd',
                backgroundColor: '#bac6bd',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
            {
                label: 'Gelöst',
                data: data.resolved,
                borderColor: '#aac465',
                backgroundColor: '#aac465',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const optionsDefectQuoteByStatus: ChartOptions<'line'> = {
        plugins: {
            tooltip: { enabled: false },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="Defektquote status"
            subtitle="Defekte | Anzahl"
            tooltip="Status der Defekte pro KW"
            live
        >
            <Line
                data={dataDefectQuoteByStatus}
                options={optionsDefectQuoteByStatus}
            />
        </AnalyticsCard>
    );
};

export default DefectQuotaByStatusCard;
