import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import fetchFluctuationPage from '../../api/kpiPeopleAndCulture/fluctuation';

const initialData: IFluctuationPage = {
    fluctuation: {
        data: [],
        labels: [],
    },
    sicknessQuota: {
        data: {
            outlet: [],
            administration: [],
            dsges: [],
        },
        labels: [],
    },
    admissions: {
        data: {
            service: [],
            cleaning: [],
            management: [],
        },
        labels: [],
    },
    personalCostsToRevenue: {
        labels: [],
        data: [],
    },
    resignations: {
        data: {
            service: [],
            cleaning: [],
            management: [],
        },
        labels: [],
    },
    recruitingCost: {
        data: {
            softgarden: [],
            indeed: [],
        },
        labels: [],
    },
    probationaryTemporarie: {
        data: [],
        labels: [],
    },
    overtimeHours: {
        data: [],
        labels: [],
    },
    openPositionDuration: {
        data: [],
        labels: [],
    },
    openPositions: {
        service: {
            data: [],
            labels: [],
        },
        cleaning: {
            data: [],
            labels: [],
        },
    },
    saldoTotal: {
        labels: [],
        data: {
            saldo: [],
            payout: [],
        },
    },
    hourlyOutput: {
        labels: [],
        data: [],
    },
    wasFetched: false,
};

const FluctuationPageContext = ProviderBuilder.buildContext<IFluctuationPage>();

const FluctuationPageProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const fluctuationPageValue = ProviderBuilder.buildProvider(initialData, fetchFluctuationPage);

    return <FluctuationPageContext.Provider value={fluctuationPageValue}>{children}</FluctuationPageContext.Provider>;
};

const useFluctuationPage = ProviderBuilder.buildUseHook(FluctuationPageContext, 'useFluctuationPage');

export default FluctuationPageProvider;
export { useFluctuationPage, FluctuationPageContext };
