import {
    GetFriendsComparisonResponse,
    GetNewFriendsByWeekResponse,
    GeneralNumberResponse,
    Timeslot,
    UtilizationByWeek,
    UtilizationByWeekResponse,
} from '@mywellness-gmbh/backend-types';
import { differenceInCalendarWeeks } from 'date-fns';
import { sendGetRequest } from '..';

const parseFutureQuotientByCalendarWeek = (data: UtilizationByWeek[]): IBookingRateData['futureQuotient'] => {
    type calendarWeek = 1 | 2 | 3 | 4;
    return data.reduce(
        (acc, curr) => {
            const outletName = curr.displayName;
            curr.utilizationByCalendarWeek.forEach((weekOfOutletData) => {
                if (!weekOfOutletData.calendarWeekStartDate) throw new Error('calendarWeekStartDate is undefined');
                const calendarWeekOffset = differenceInCalendarWeeks(new Date(weekOfOutletData.calendarWeekStartDate), new Date(), {
                    weekStartsOn: 1,
                });

                // throw an error, if the calendarWeekOffset is not 1, 2, 3 or 4
                if (calendarWeekOffset < 1 || calendarWeekOffset > 4) {
                    throw new Error('calendarWeekOffset is not 1, 2, 3 or 4');
                }

                const week = calendarWeekOffset as calendarWeek;
                acc[week - 1].push({
                    outletName,
                    quotient: Math.floor(weekOfOutletData.utilization),
                });
            });
            return acc;
        },
        [[], [], [], []] as IBookingRateData['futureQuotient'],
    );
};

export const fetchKpiBookingRate = async (token: string): Promise<IBookingRateData> => {
    const bookingRateRequest = sendGetRequest<UtilizationByWeekResponse>(token, `/statistics/utilization/byWeek?timeslot=nextTwelveWeeks`);

    const futureQuotientRequest = sendGetRequest<UtilizationByWeekResponse>(
        token,
        `/statistics/utilization/byWeek?timeslot=nextFourWeeks&groupByOutlet=true`,
    );

    const kpiWorkloadTotalRequest = sendGetRequest<UtilizationByWeekResponse>(
        token,
        `/statistics/utilization/byWeek?timeslot=${Timeslot.twelveWeeks}`,
    );
    const kpiWorkloadPerOutletRequest = sendGetRequest<UtilizationByWeekResponse>(
        token,
        '/statistics/utilization/byWeek?timeslot=lastWeek&groupByOutlet=true',
    );

    const newFriendsRequest = sendGetRequest<GetNewFriendsByWeekResponse>(
        token,
        `/statistics/friends/newFriendsByWeek?timeslot=${Timeslot.twelveWeeks}`,
    );

    const subscribersThatBecameFriendsRequest = sendGetRequest<GeneralNumberResponse>(token, '/statistics/friends/fromSubscribers');

    const friendsBookingComparisonRequest = sendGetRequest<GetFriendsComparisonResponse>(
        token,
        `/statistics/friends/bookingComparison?timeslot=${Timeslot.fourMonths}`,
    );

    const friendsRevenueComparisonRequest = sendGetRequest<GetFriendsComparisonResponse>(
        token,
        `/statistics/friends/revenueComparison?timeslot=${Timeslot.fourMonths}`,
    );

    const [
        bookingRate,
        futureQuotient,
        kpiWorkloadTotal,
        kpiWorkloadPerOutlet,
        newFriends,
        subscribersThatBecameFriends,
        friendsBookingComparison,
        friendsRevenueComparison,
    ] = await Promise.all([
        bookingRateRequest,
        futureQuotientRequest,
        kpiWorkloadTotalRequest,
        kpiWorkloadPerOutletRequest,
        newFriendsRequest,
        subscribersThatBecameFriendsRequest,
        friendsBookingComparisonRequest,
        friendsRevenueComparisonRequest,
    ]);

    const bookingRateRes = bookingRate.result[0]
        ? bookingRate.result[0].utilizationByCalendarWeek
              .sort((a, b) => {
                  if (a.calendarWeekStartDate && b.calendarWeekStartDate) {
                      // cast to date and compare
                      return new Date(a.calendarWeekStartDate).getTime() - new Date(b.calendarWeekStartDate).getTime();
                  }
                  // compare by calendarWeek
                  return a.calendarWeek - b.calendarWeek;
              })
              .map((member) => ({
                  value: parseInt(member.utilization.toString(), 10),
                  label: `KW ${member.calendarWeek}`,
              }))
        : [];

    futureQuotient.result.map((member) => ({
        label: member.displayName,
        value: parseInt(
            (
                member.utilizationByCalendarWeek.reduce((acc, curr) => {
                    const util = acc + curr.utilization;
                    return util;
                }, 0) / member.utilizationByCalendarWeek.length
            ).toString(),
            10,
        ),
    }));

    return {
        bookingRate: bookingRateRes,
        futureQuotient: parseFutureQuotientByCalendarWeek(futureQuotient.result),
        utilizationTotal: kpiWorkloadTotal.result[0]?.utilizationByCalendarWeek.map((member) => ({
            label: `KW ${member.calendarWeek}`,
            value: parseInt(member.utilization.toString(), 10),
        })),
        utilizationByOutlet: kpiWorkloadPerOutlet.result.map((member) => ({
            label: member.displayName,
            value: parseInt(member.utilizationByCalendarWeek[0]?.utilization?.toString() || '0', 10),
        })),
        newFriends: newFriends.result,
        subscribersThatBecameFriends: subscribersThatBecameFriends.result,
        friendsBookingComparison: friendsBookingComparison.result.map((item) => ({
            month: item.month,
            friendsBookingCount: item.valueByFriends,
            nonFriendsBookingCount: item.valueByNonFriends,
        })),
        friendsRevenueComparison: friendsRevenueComparison.result.map((item) => ({
            month: item.month,
            friendsRevenue: item.valueByFriends,
            nonFriendsRevenue: item.valueByNonFriends,
        })),
        wasFetched: true,
    };
};
