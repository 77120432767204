import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

type UserContextType = {
    user: IUser | undefined;
    setUser: (user: IUser) => void;
    clearUser: () => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const localStorageUser = localStorage.getItem('user') || undefined;
    const [userState, setUserState] = useState<IUser | undefined>(localStorageUser ? (JSON.parse(localStorageUser) as IUser) : undefined);

    const setUser = useCallback((user: IUser): void => {
        setUserState(user);
        localStorage.setItem('user', JSON.stringify(user));
    }, []);

    const clearUser = useCallback((): void => {
        setUserState(undefined);
    }, []);

    const userValue = useMemo(
        () => ({
            user: userState,
            setUser,
            clearUser,
        }),
        [userState, setUser, clearUser],
    );

    return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>;
};

const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export default UserProvider;
export { useUser, UserContext };
