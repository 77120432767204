import { Chart as ChartJS, ArcElement, Legend, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, BarElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FunctionComponent, useEffect } from 'react';
import { useOutletComparisonData } from '../context/KPIQuality/KPIOutletComparisonProvider';
import LoadSpinner from '../components/LoadSpinner';
import OutletSalesComparisonCard from '../components/analytics/cards/OutletSalesComparison';
import OutletFeedbackComparisonCard from '../components/analytics/cards/OutletFeedbackComparison';
import OutletProductivity from '../components/analytics/cards/OutletProductivity';
import ResignationInProbationary from '../components/analytics/cards/ResignationInProbationary';

ChartJS.register(LineElement, BarElement, ArcElement, Legend, CategoryScale, LinearScale, PointElement, Tooltip, ChartDataLabels);

const KPIOutletComparison: FunctionComponent = () => {
    const { data, fetchData } = useOutletComparisonData();

    useEffect(() => {
        fetchData();
    }, []);

    if (!data.wasFetched) {
        return <LoadSpinner />;
    }
    return (
        <>
            <OutletProductivity
                data={data.productivity.data}
                labels={data.productivity.labels}
                fetchData={fetchData}
            />
            <ResignationInProbationary
                data={data.resignationInProbationary.data}
                labels={data.resignationInProbationary.labels}
                fetchData={fetchData}
            />
            <OutletSalesComparisonCard
                data={data.outletSales.data}
                labels={data.outletSales.labels}
                fetchData={fetchData}
            />
            <OutletFeedbackComparisonCard
                data={data.outletFeedback.data.friendliness}
                labels={data.outletFeedback.labels}
                counts={data.outletFeedback.counts.friendliness}
                fetchData={fetchData}
                title="Feedback Freundlichkeit"
                subtitle="Durschnittsbewertungen Freundlichkeit Letzte Woche"
            />
            <OutletFeedbackComparisonCard
                data={data.outletFeedback.data.punctuality}
                labels={data.outletFeedback.labels}
                counts={data.outletFeedback.counts.punctuality}
                fetchData={fetchData}
                title="Feedback Pünktlichkeit"
                subtitle="Durschnittsbewertungen Pünktlichkeit Letzte Woche"
            />
            <OutletFeedbackComparisonCard
                data={data.outletFeedback.data.cleanliness}
                labels={data.outletFeedback.labels}
                counts={data.outletFeedback.counts.cleanliness}
                fetchData={fetchData}
                title="Feedback Sauberkeit"
                subtitle="Durschnittsbewertungen Sauberkeit Letzte Woche"
            />
        </>
    );
};

export default KPIOutletComparison;
