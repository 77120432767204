import { Timeslot } from '@mywellness-gmbh/backend-types';
import { getAbsoluteDataMulti, getSingleLineGraph, loadAnalyticsData } from '../storedAnalytics';
import { getNameOfMonth } from '../../util';

const getSicknessQuota = async (token: string): Promise<ISicknessQuota> => {
    const data = await loadAnalyticsData(token, 'sickness_quota', Timeslot.lastTwelveMonths);
    const labels = data.result.map((sicknessRateData) => getNameOfMonth(parseInt(sicknessRateData.interval.split('/')[0], 10)));
    const values = data.result.reduce(
        (acc, curr) => {
            const outlet = curr.values.find((value) => value.identifier === 'outlet');
            const administration = curr.values.find((value) => value.identifier === 'administration');
            const dsges = curr.values.find((value) => value.identifier === 'dsges');

            acc.outlet.push(outlet?.value || 0);
            acc.administration.push(administration?.value || 0);
            acc.dsges.push(dsges?.value || 0);
            return acc;
        },
        { outlet: [], administration: [], dsges: [] } as Pick<ISicknessQuota, 'data'>['data'],
    );

    return {
        data: values,
        labels,
    };
};

const fetchFluctuationPage = async (token: string): Promise<IFluctuationPage> => {
    const fluctuationRequest = getSingleLineGraph(token, 'fluctuation', Timeslot.lastTwelveMonths);
    const sicknessQuotaRequest = getSicknessQuota(token);
    const probationaryTemporarieRequest = getAbsoluteDataMulti(token, 'probationary_temporary', Timeslot.lastTwelveMonths, true);
    const overtimeHoursRequest = getAbsoluteDataMulti(token, 'overtime_hours', Timeslot.lastTwelveMonths, false);

    const openPositionsServiceRequest = loadAnalyticsData(token, 'open_positions_service', Timeslot.lastMonth);
    const openPositionsCleaningRequest = loadAnalyticsData(token, 'open_positions_cleaning', Timeslot.lastMonth);

    const openPositionDurationRequest = loadAnalyticsData(token, 'open_positions_duration', Timeslot.lastTwelveMonths);
    const admissionsRequest = loadAnalyticsData(token, 'admissions', Timeslot.lastTwelveMonths);
    const resignationsRequest = loadAnalyticsData(token, 'resignations', Timeslot.lastTwelveMonths);

    const recruitingCostsRequest = loadAnalyticsData(token, 'recruiting_cost', Timeslot.lastTwelveMonths);
    const saldoTotalRequest = loadAnalyticsData(token, 'saldo_total', Timeslot.lastTwelveMonths);

    const personalCostsToRevenueRequest = loadAnalyticsData(token, 'personal_costs_to_revenue', Timeslot.lastTwelveMonths);

    const hourlyOutputRequest = getSingleLineGraph(token, 'hourly_output');

    const [
        fluctuation,
        recruitingCosts,
        sicknessQuota,
        probationaryTemporarie,
        overtimeHours,
        openPositionDuration,
        openPositionsService,
        openPositionsCleaning,
        admissions,
        resignations,
        saldoTotal,
        personalCostsToRevenue,
        hourlyOutput,
    ] = await Promise.all([
        fluctuationRequest,
        recruitingCostsRequest,
        sicknessQuotaRequest,
        probationaryTemporarieRequest,
        overtimeHoursRequest,
        openPositionDurationRequest,
        openPositionsServiceRequest,
        openPositionsCleaningRequest,
        admissionsRequest,
        resignationsRequest,
        saldoTotalRequest,
        personalCostsToRevenueRequest,
        hourlyOutputRequest,
    ]);

    return {
        fluctuation: {
            ...fluctuation,
            labels: fluctuation.labels.map((label) => getNameOfMonth(parseInt(label.split('/')[0], 10))),
        },
        sicknessQuota,
        admissions: {
            labels: admissions.result.map((value) => getNameOfMonth(parseInt(value.interval.split('/')[0], 10))),
            data: {
                cleaning: admissions.result.map((value) => value.values.filter((val) => val.identifier === 'reinigung')[0]?.value ?? 0),
                service: admissions.result.map((value) => value.values.filter((val) => val.identifier === 'service')[0]?.value ?? 0),
                management: admissions.result.map((value) => value.values.filter((val) => val.identifier === 'management')[0]?.value ?? 0),
            },
        },
        resignations: {
            labels: resignations.result.map((value) => getNameOfMonth(parseInt(value.interval.split('/')[0], 10))),
            data: {
                cleaning: resignations.result.map((value) => value.values.filter((val) => val.identifier === 'reinigung')[0]?.value ?? 0),
                service: resignations.result.map((value) => value.values.filter((val) => val.identifier === 'service')[0]?.value ?? 0),
                management: resignations.result.map(
                    (value) => value.values.filter((val) => val.identifier === 'management')[0]?.value ?? 0,
                ),
            },
        },
        recruitingCost: {
            labels: recruitingCosts.result.map((value) => getNameOfMonth(parseInt(value.interval.split('/')[0], 10))),
            data: {
                softgarden: recruitingCosts.result.map(
                    (value) => value.values.filter((val) => val.identifier === 'softgarden')[0]?.value ?? 0,
                ),
                indeed: recruitingCosts.result.map((value) => value.values.filter((val) => val.identifier === 'indeed')[0]?.value ?? 0),
            },
        },
        probationaryTemporarie,
        overtimeHours,
        openPositionDuration: {
            data: openPositionDuration.result.map((value) => value.values[0]?.value ?? 0),
            labels: openPositionDuration.result.map((value) => getNameOfMonth(parseInt(value.interval.split('/')[0], 10))),
        },
        openPositions: {
            service: {
                data: openPositionsService.result[0].values.map((value) => value.value),
                labels: openPositionsService.result[0].values.map((value) => value.identifier),
            },
            cleaning: {
                data: openPositionsCleaning.result[0].values.map((value) => value.value),
                labels: openPositionsCleaning.result[0].values.map((value) => value.identifier),
            },
        },
        saldoTotal: {
            labels: saldoTotal.result.map((value) => getNameOfMonth(parseInt(value.interval.split('/')[0], 10))),
            data: {
                saldo: saldoTotal.result.map((value) => value.values.filter((val) => val.identifier === 'saldo')[0]?.value ?? 0),
                payout: saldoTotal.result.map((value) => value.values.filter((val) => val.identifier === 'payout')[0]?.value ?? 0),
            },
        },
        personalCostsToRevenue: {
            labels: personalCostsToRevenue.result.map((value) => getNameOfMonth(parseInt(value.interval.split('/')[0], 10))),
            data: personalCostsToRevenue.result.map((value) => value.values[0]?.value ?? 0),
        },
        hourlyOutput,
        wasFetched: true,
    };
};

export default fetchFluctuationPage;
