import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import StatPairs from '../StatPairs';

const AveragePersonsCard: FunctionComponent<GraphAnalyticsCardProps<IAveragePersons>> = ({ data }) => {
    return (
        <AnalyticsCard
            live
            title="Personenzahl"
            subtitle="pro Buchung"
            tooltip="Die Durschnittliche Personenanzahl"
        >
            <StatPairs
                first={data.averageThisWeek.toString()}
                firstName="Diese Woche"
                firstType="avg"
                second={data.averageLastWeek.toString()}
                secondName="Vorwoche"
                trend={data.rankIndicator}
            />
        </AnalyticsCard>
    );
};
export default AveragePersonsCard;
