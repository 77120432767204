import { FunctionComponent } from 'react';
import RankIndicator from '../../RankIndicator';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const AverageOfStayCard: FunctionComponent<GraphAnalyticsCardProps<IAverageStay>> = ({ data }) => {
    // TODO: Refactor and rename
    const getMinutes = (number: number): string => {
        const roundedNumber = parseFloat(number.toFixed(2));
        const hoursAndMinutes = (roundedNumber / 60).toFixed(2);
        const decimalPosition = hoursAndMinutes.toString().indexOf('.');
        const minutes = hoursAndMinutes.toString().substring(decimalPosition + 1);
        return minutes;
    };

    // TODO: Refactor and rename
    const getHours = (number: number): number => {
        const roundedNumber = parseFloat(number.toFixed(2));
        const hoursAndMinutes = roundedNumber / 60;
        const hours = Math.floor(hoursAndMinutes);
        return hours;
    };

    return (
        <AnalyticsCard
            live
            title="Aufenthaltsdauer"
            subtitle="Durschnitt"
            tooltip="Die Durschnittliche Aufenthaltsdauer"
        >
            <div className="grid grid-cols-1a1 h-full">
                <div className="text-right self-end">
                    <p className="text-5.5xl font-bold">
                        {getHours(data.averageThisWeek)}&nbsp;h {getMinutes(data.averageThisWeek)}&nbsp;Min.
                    </p>
                    <p className="text-2xl text-right mt-11">Diese Woche</p>
                </div>
                <RankIndicator direction={data.rankIndicator} />
                <div className="self-end">
                    <p className="text-2xl">
                        {getHours(data.averageLastWeek)}&nbsp;h {getMinutes(data.averageLastWeek)}&nbsp;Min.
                    </p>
                    <p className="text-2xl mt-11">Vorwoche</p>
                </div>
            </div>
        </AnalyticsCard>
    );
};

export default AverageOfStayCard;
