import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

// TODO: Refactor this type
const ConversionByChannelCard: FunctionComponent<GraphAnalyticsCardProps<IMultiLineGraphData[]>> = ({ data, fetchData, labels }) => {
    // Conversions Käufe - Line Chart
    const dataConversionByChannel: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Direct',
                data: data.find((item) => item.label === 'direct')?.data || [],
                borderColor: '#bac6bd',
                backgroundColor: '#bac6bd',
                pointBackgroundColor: '#bac6bd',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'Display',
                data: data.find((item) => item.label === 'display')?.data || [],
                borderColor: '#edcd77',
                backgroundColor: '#edcd77',
                pointBackgroundColor: '#edcd77',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'SEO',
                data: data.find((item) => item.label === 'seo')?.data || [],
                borderColor: '#e6765a',
                backgroundColor: '#e6765a',
                pointBackgroundColor: '#e6765a',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'white',
                },
            },
            {
                label: 'SEA',
                data: data.find((item) => item.label === 'sea')?.data || [],
                borderColor: '#558ba0',
                backgroundColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'YouTube',
                data: data.find((item) => item.label === 'youtube')?.data || [],
                borderColor: '#eb6253',
                backgroundColor: '#eb6253',
                pointBackgroundColor: '#eb6253',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'Meta Ads',
                data: data.find((item) => item.label === 'meta_ads')?.data || [],
                borderColor: '#738B7A',
                backgroundColor: '#738B7A',
                pointBackgroundColor: '#738B7A',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'Pinterest Ads',
                data: data.find((item) => item.label === 'pinterest_ads')?.data || [],
                borderColor: '#90AF76',
                backgroundColor: '#90AF76',
                pointBackgroundColor: '#90AF76',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'TikTok Ads',
                data: data.find((item) => item.label === 'tiktok_ads')?.data || [],
                borderColor: '#2d435b',
                backgroundColor: '#2d435b',
                pointBackgroundColor: '#2d435b',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'Newsletter',
                data: data.find((item) => item.label === 'newsletter')?.data || [],
                borderColor: '#a1c1ce',
                backgroundColor: '#a1c1ce',
                pointBackgroundColor: '#a1c1ce',
                pointRadius: 9,
                pointHoverRadius: 12,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
        ],
    };

    const optionsConversionByChannel: ChartOptions<'line'> = {
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
        },
        layout: {
            padding: {
                top: 5,
            },
        },
        interaction: {
            mode: 'nearest',
            intersect: false,
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Conversions (Käufe)"
            subtitle="Nach Kanal | in Tsd. €"
            tooltip="?"
            editableConfigIdentifier="conversions_by_channel"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Line
                data={dataConversionByChannel}
                options={optionsConversionByChannel}
            />
        </AnalyticsCard>
    );
};

export default ConversionByChannelCard;
