import { FunctionComponent } from 'react';
import RankIndicator from '../RankIndicator';
import StarRating from '../StarRating';

interface StatPairProps {
    first: string;
    firstName?: string;
    firstType?: 'stars' | 'avg';
    second: string;
    secondName?: string;
    trend?: 'up' | 'down' | 'equal';
    currentScoreOpacity?: string;
}

const StatPairs: FunctionComponent<StatPairProps> = ({
    first,
    firstName = 'Diese Woche',
    firstType,
    second,
    secondName = 'Vorwoche',
    trend,
    currentScoreOpacity,
}) => (
    <div
        className="grid grid-cols-1a1 transition-opacity h-full duration-500"
        style={{ opacity: currentScoreOpacity }}
    >
        <div className="text-right self-end">
            <span className="mt-3.5 text-2xl text-center">
                {firstType === 'avg' && <span className="my-auto mr-4 font-bold text-7xl relative top-2">&Oslash;</span>}
                {firstType === 'stars' ? <StarRating number={Number(first)} /> : <span className="text-5.5xl font-bold">{first}</span>}
            </span>
            <p className="mt-11 text-2xl">{firstName}</p>
        </div>
        {trend && <RankIndicator direction={trend} />}
        <div className="text-left self-end">
            <p className="text-2xl">{second}</p>
            <p className="mt-11 text-2xl">{secondName}</p>
        </div>
    </div>
);

export default StatPairs;
