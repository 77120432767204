import { ChartData, Point, TooltipItem } from 'chart.js';
import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OutletFeedbackComparisonCard: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels, counts, title, subtitle }) => {
    // Feedbackvergleich der letzten Woche aller Filialen - Bar chart
    const dataOutletFeedback: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                    color: 'black',
                },
            },
        ],
    };

    const dataOutletFeedbackOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
                display: true,
            },
            y: {
                beginAtZero: true,
                display: false,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label(tooltipItem: TooltipItem<'bar'>): string {
                        const { dataIndex } = tooltipItem;
                        const label = tooltipItem.dataset.label || '';
                        let count = 0;
                        if (counts) {
                            count = counts[dataIndex] ?? 0;
                        }
                        return `${label}: ${tooltipItem.raw} (Anzahl: ${count})`;
                    },
                },
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <AnalyticsCard
            title={title || 'Feedback'}
            subtitle={subtitle}
            live
        >
            <Bar
                data={dataOutletFeedback}
                options={dataOutletFeedbackOptions}
            />
        </AnalyticsCard>
    );
};

export default OutletFeedbackComparisonCard;
