import { FunctionComponent } from 'react';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';
import PieChart from '../../PieChart';

const GenderCard: FunctionComponent<GraphAnalyticsCardProps<ICustomerGender>> = ({ data }) => {
    return (
        <AnalyticsCard
            live
            title="Geschlecht"
            tooltip="Anteil Geschlecht / Alle Buchungen"
        >
            <div className="grid grid-cols-1a1">
                <PieChart
                    title="Weiblich"
                    percent={data.percentFemale.toString()}
                />
                <span />
                <PieChart
                    title="Männlich"
                    percent={data.percentMale.toString()}
                />
            </div>
        </AnalyticsCard>
    );
};

export default GenderCard;
