import { ChartData, ChartOptions, Point } from 'chart.js';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const ROASByChannelCard: FunctionComponent<GraphAnalyticsCardProps<IMultiLineGraphData[]>> = ({ data, fetchData, labels }) => {
    // ROAS - Line Chart
    const dataROAS: ChartData<'line', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'SEA',
                data: data.find((item) => item.label === 'sea')?.data || [],
                borderColor: '#558ba0',
                backgroundColor: '#558ba0',
                pointBackgroundColor: '#558ba0',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'Display',
                data: data.find((item) => item.label === 'display')?.data || [],
                borderColor: '#edcd77',
                backgroundColor: '#edcd77',
                pointBackgroundColor: '#edcd77',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'YouTube',
                data: data.find((item) => item.label === 'youtube')?.data || [],
                borderColor: '#eb6253',
                backgroundColor: '#eb6253',
                pointBackgroundColor: '#eb6253',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'Meta Ads',
                data: data.find((item) => item.label === 'meta_ads')?.data || [],
                borderColor: '#738B7A',
                backgroundColor: '#738B7A',
                pointBackgroundColor: '#738B7A',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    color: 'white',
                },
            },
            {
                label: 'Pinterest Ads',
                data: data.find((item) => item.label === 'pinterest_ads')?.data || [],
                borderColor: '#90AF76',
                backgroundColor: '#90AF76',
                pointBackgroundColor: '#90AF76',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
            {
                label: 'TikTok Ads',
                data: data.find((item) => item.label === 'tiktok_ads')?.data || [],
                borderColor: '#2d435b',
                backgroundColor: '#2d435b',
                pointBackgroundColor: '#2d435b',
                pointRadius: 8,
                pointHoverRadius: 10,
                tension: 0.4,
                datalabels: {
                    color: 'black',
                },
            },
        ],
    };

    const optionsROAS: ChartOptions<'line'> = {
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
        },
        layout: {
            padding: {
                top: 5,
            },
        },
        interaction: {
            mode: 'nearest',
            intersect: false,
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };
    return (
        <AnalyticsCard
            title="ROAS"
            tooltip="Roas nach Kanal der letzten 12 Wochen"
            editableConfigIdentifier="roas_by_channel"
            onSaveEditableConfig={(): void => {
                if (fetchData) {
                    fetchData();
                }
            }}
        >
            <Line
                data={dataROAS}
                options={optionsROAS}
            />
        </AnalyticsCard>
    );
};

export default ROASByChannelCard;
