import { FunctionComponent } from 'react';
import { ChartData, ChartOptions, Point } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const FriendsBookingComparison: FunctionComponent<
    GraphAnalyticsCardProps<{
        nonFriendsBookingCount: number[];
        friendsBookingCount: number[];
    }>
> = ({ data, labels }) => {
    // Marketing-Kosten - Bar Chart
    const chartData: ChartData<'bar', (number | Point | null)[], unknown> = {
        labels,
        datasets: [
            {
                label: 'Friends',
                data: data.friendsBookingCount,
                backgroundColor: ['#558ba0'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'white',
                },
            },
            {
                label: 'Non-Friends',
                data: data.nonFriendsBookingCount,
                backgroundColor: ['#a1c1ce'],
                datalabels: {
                    font: {
                        weight: 'bold',
                        size: 18,
                    },
                    color: 'black',
                },
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                display: true,
                align: 'center',
            },
            legend: {
                display: true,
                position: 'top',
                labels: {
                    usePointStyle: true,
                },
            },
        },
        scales: {
            y: {
                display: false,
                stacked: true,
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Buchungen"
            subtitle="durch Friends und Non-Friends"
            tooltip="Anzahl der durch Friends getätigten Buchungen im Vergleich zu Non-Friends für die letzten drei Monate"
            live
        >
            <Bar
                data={chartData}
                options={options}
            />
        </AnalyticsCard>
    );
};

export default FriendsBookingComparison;
