import { PropsWithChildren } from 'react';
import { ProviderBuilder } from '../ProviderBuilder';
import { fetchKPIMarketing } from '../../api/kpiMarketing/salesVolume';

const initialData: ISalesVolumeData = {
    totalOrderVolume: [],
    socialAttention: {
        labels: [],
        data: {
            currentCw: [],
            currentMinusOne: [],
            currentMinusTwo: [],
        },
    },
    engagementRate: {
        labels: [],
        data: {
            currentCw: [],
            currentMinusOne: [],
            currentMinusTwo: [],
        },
    },
    voucherPerMonth: {
        labels: [],
        data: {
            sold: [],
            redeemed: [],
        },
    },
    voucherPerYear: {
        labels: [],
        data: {
            sold: [],
            redeemed: [],
        },
    },
    wasFetched: false,
};

const SalesVolumeDataContext = ProviderBuilder.buildContext<ISalesVolumeData>();

const SalesVolumeDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const kpiMarketingDataValue = ProviderBuilder.buildProvider<ISalesVolumeData>(initialData, fetchKPIMarketing);

    return <SalesVolumeDataContext.Provider value={kpiMarketingDataValue}>{children}</SalesVolumeDataContext.Provider>;
};

const useSalesVolumeData = ProviderBuilder.buildUseHook(SalesVolumeDataContext, 'useSalesVolumeData');

export default SalesVolumeDataProvider;
export { useSalesVolumeData, SalesVolumeDataContext };
